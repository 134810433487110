import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { PostTriage, Triage } from 'src/app/model/Triage';
import { environment } from 'src/environments/environment';
import * as fromApp from '../../../store/app.reducer';
import * as RichiesteActions from './richieste.actions';
import { deleteRichiestaSuccess } from './richieste.actions';

@Injectable()
export class RichiesteEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private router: Router
  ) {}

  fetchAllRichieste$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchAllRichieste),
      switchMap((action) => {
        let url = environment.NEW_API + '/requests';
        if (action.searchRequest) {
          let filter = [];
          if (action.statusValue) {
            filter.push({
              field: 'status',
              operation: 'EQ',
              value: action.statusValue,
            });
          }
          if (action.fromDate) {
            filter.push({
              field: 'slot.from',
              operation: 'EQ',
              value: action.fromDate,
            });
          }
          if (action.toDate) {
            filter.push({
              field: 'slot.to',
              operation: 'EQ',
              value: action.toDate,
            });
          }
          const searchValue = {
            filter: {
              filterBy: {
                operation: 'AND',
                filters: filter,
              },
            },
            pagination: {
              page: action.page || 0,
              size: 20,
            },
            sort: [
              {
                direction: 'DESC',
                field: 'start',
              },
            ],
          };
          const base64 = btoa(JSON.stringify(searchValue));
          url += '?searchRequest=' + base64;
        }
        return this.http.get<Richiesta>(url);
      }),
      map((richieste) => {
        if (richieste) {
          return RichiesteActions.fetchAllRichiesteSuccess({
            richieste: richieste,
          });
        } else {
          return RichiesteActions.fetchAllRichiesteFailure({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchAllRichiesteFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRequests),
      switchMap((action) => {
        return this.fetchRichieste(action);
      }),
      map((richieste) => {
        return RichiesteActions.fetchRequestsSuccess({
          richieste: richieste,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchRequestsFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchRichiestaDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiesteDetails),
      switchMap((action) => {
        return this.http.get<any>(
          environment.NEW_API + '/requests/' + action.richiestaId
        );
      }),
      pipe(
        map((richiesta) => {
          if (richiesta) {
            return RichiesteActions.fetchRichiestaDetailsSuccess({
              richiesta: richiesta.data,
            });
          } else {
            return RichiesteActions.fetchRichiestaDetailsFailure({
              error: 'error: Fetch details failed',
            });
          }
        }),
        catchError((error) => {
          console.error('Error while fetching:', error);
          return of(
            RichiesteActions.fetchRichiestaDetailsFailure({
              error:
                'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
            })
          );
        })
      )
    )
  );

  deleteRichiesta$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.deleteRichiesta),
      switchMap((action) =>
        this.http
          .delete(environment.NEW_API + '/requests/' + action.id)
          .pipe(
            map(() => deleteRichiestaSuccess({ id: action.id })),
            catchError((error) => {
              console.error('Error while deleting:', error);
              if(error.error.message.includes('The request was cancellable until')) {
                error.error.message = 'È possibile cancellare gli appuntamenti fino a 6 ore dalla data d\'inizio.'
              } else if (error.error.message.includes('only free requests can be deleted')) {
                error.error.message = 'Solo i videoconsulti gratuiti possono essere cancellati.'
              } else {
                error.error.message = 'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.'
              }
              return of(
                RichiesteActions.deleteRichiestaFailed({
                  error: error.error.message,
                })
              );
            })
          )
      )
    )
  );

  deleteSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RichiesteActions.deleteRichiestaSuccess),
        tap(() => this.router.navigate(['/loading']))
      ),
    { dispatch: false }
  );

  fetchTriage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchTriage),
      switchMap((action) =>
        this.http
          .get<Triage>(
            environment.NEW_API + '/requests/' + action.richiestaId + '/triage'
          )
          .pipe(
            map((triage) => {
              if (triage) {
                return RichiesteActions.fetchTriageSuccess({
                  triage: triage.data,
                });
              } else {
                return RichiesteActions.fetchTriageFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.fetchTriageFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  postTriage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.postTriage),
      switchMap((action) =>
        this.http
          .patch<PostTriage>(
            environment.NEW_API + '/requests/' + action.richiestaId + '/triage',
            action.triage
          )
          .pipe(
            map((triage) => {
              if (triage) {
                return RichiesteActions.postTriageSuccess({
                  triage: triage,
                });
              } else {
                return RichiesteActions.postTriageFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.postTriageFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  postTriageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.postTriageSuccess),
      map(() => {
        this.router.navigate(['/richieste']);
        return RichiesteActions.fetchAllRichieste({});
      })
    )
  );

  fetchRichiestaDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiestaDocuments),
      switchMap((action) =>
        this.http
          .get<any>(
            environment.NEW_API +
              '/requests/' +
              action.richiestaId +
              '/documents'
          )
          .pipe(
            map((documents) => {
              if (documents) {
                let idDocs = documents.data.map((x: any) => x.id);
                return RichiesteActions.fetchRichiestaDocumentsSuccess({
                  documents: documents.data,
                  ids: idDocs,
                });
              } else {
                return RichiesteActions.fetchRichiestaDocumentsFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.fetchRichiestaDocumentsFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  fetchRichiestaMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchRichiestaMeeting),
      switchMap((action) =>
        this.http
          .get<any>(
            environment.NEW_API + '/requests/' + action.richiestaId + '/meeting'
          )
          .pipe(
            map((meeting) => {
              if (meeting) {
                return RichiesteActions.fetchRichiestaMeetingSuccess({
                  meeting: meeting.data,
                });
              } else {
                return RichiesteActions.fetchRichiestaMeetingFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.fetchRichiestaMeetingFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  fetchCalendarRequests$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchCalendarRequests),
      switchMap((action) => {
        let url = environment.NEW_API + '/requests/calendar';
        if (action.status) {
          url += '?requestStatus=' + action.status;
        }
        if (action.fromDate) {
          url += '&fromDate=' + action.fromDate;
        }
        if (action.toDate) {
          url += '&toDate=' + action.toDate;
        }
        if (action.page && action.pageSize) {
          url += '&page=' + action.page + '&pageSize=' + action.pageSize;
        }
        const urlDebug =
          environment.NEW_API +
          '/requests/calendar?requestStatus=OPEN&fromDate=2022-06-01&toDate=2022-06-30';
        return this.http.get<Richiesta>(url);
      }),
      map((richieste) => {
        if (richieste) {
          return RichiesteActions.fetchCalendarRequestsSuccess({
            requests: richieste.data,
          });
        } else {
          return RichiesteActions.fetchCalendarRequestsFailed({
            error: 'error: ',
          });
        }
      }),
      catchError((error) => {
        console.error('Error while fetching calendar:', error);
        return of(
          RichiesteActions.fetchAllRichiesteFailure({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  myWellnessShareStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.myWellnessShareStatus),
      switchMap((action) =>
        this.http
          .get<any>(
            environment.NEW_API +
              '/my-wellness/' +
              action.doctorId +
              '/share/status/'
          )
          .pipe(
            map((myWellness) => {
              if (myWellness && myWellness.data) {
                return RichiesteActions.myWellnessShareStatusSuccess({
                  status: myWellness.data.myWellnessShareStatus,
                });
              } else {
                return RichiesteActions.myWellnessShareStatusFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.myWellnessShareStatusFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  myWellnessShare$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.myWellnessShare),
      switchMap((action) => {
        return this.http.patch<any>(
          environment.NEW_API + '/my-wellness/' + action.doctorId + '/share',
          JSON.stringify('OK'),
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
            }),
          }
        );
      }),
      map((movergy) => {
        return RichiesteActions.myWellnessShareSuccess({
          movergy: movergy.data,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching movergy:', error);
        return of(
          RichiesteActions.myWellnessShareFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  linkDocToRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.linkDocToRequest),
      switchMap((action) =>
        this.http
          .post<any>(
            environment.NEW_API +
              '/requests/' +
              action.requestId +
              '/documents',
            { documentId: action.documentId }
          )
          .pipe(
            map((linkToReq) => {
              if (linkToReq) {
                return RichiesteActions.linkDocToRequestSuccess({
                  requestId: action.requestId,
                });
              } else {
                return RichiesteActions.linkDocToRequestFailed({
                  error: 'error: ',
                });
              }
            }),
            catchError((error) => {
              console.error('Error while fetching:', error);
              return of(
                RichiesteActions.linkDocToRequestFailed({
                  error:
                    'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
                })
              );
            })
          )
      )
    )
  );

  linkDocToReqSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.linkDocToRequestSuccess),
      map((action) => {
        return RichiesteActions.fetchRichiestaDocuments({
          richiestaId: action.requestId,
        });
      })
    )
  );

  fetchOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchOrders),
      switchMap(() => {
        return this.http.get<any>(environment.NEW_API + '/orders');
      }),
      map((orders) => {
        if (orders) {
          return RichiesteActions.fetchOrdersSuccess({
            orders: orders.data,
          });
        } else {
          return RichiesteActions.fetchOrdersFailed({
            error: 'error: ',
          });
        }
      })
    )
  );

  orderCheckout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RichiesteActions.orderCheckout),
      switchMap((action) => {
        let body: any = {
          successUrl: action.successUrl,
          cancelUrl: action.cancelUrl,
        };
        if (action.couponCode) {
          body.couponCode = action.couponCode;
        }
        return this.http.post<any>(
          environment.NEW_API + '/orders/' + action.orderId + '/checkout',
          body
        );
      }),
      map((data) => {
        return RichiesteActions.orderCheckoutSuccess({
          checkOutUrl: data,
        });
      }),
      catchError((error) => {
        return of(RichiesteActions.orderCheckoutFail({ error: error }));
      })
    );
  });

  orderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RichiesteActions.orderCheckoutSuccess),
        tap((action) => {
          window.location.href = action.checkOutUrl.data.checkOutUrl;
        })
      ),
    { dispatch: false }
  );

  fetchSurveys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RichiesteActions.fetchSurvey),
      switchMap(() => {
        return this.fetchSurvey();
      }),
      map((surveys) => {
        return RichiesteActions.fetchSurveySuccess({
          survey: surveys,
        });
      }),
      catchError((error) => {
        console.error('Error while fetching:', error);
        return of(
          RichiesteActions.fetchSurveyFailed({
            error:
              'Si è verificato un errore. Prego ricaricare la pagina o riprovare più tardi.',
          })
        );
      })
    )
  );

  fetchRichieste(action: any) {
    let url = environment.NEW_API + '/requests';
    if (action.searchRequest) {
      const searchValue = {
        filter: {
          filterBy: {
            operation: 'AND',
            filters: [
              {
                field: 'status',
                operation: 'EQ',
                value: action.statusValue,
              },
              {
                field: 'slot.from',
                operation: 'EQ',
                value: action.fromDate,
              },
              {
                field: 'slot.to',
                operation: 'EQ',
                value: action.toDate,
              },
            ],
          },
        },
        pagination: {
          page: action.page,
          size: 20,
        },
        sort: [
          {
            direction: 'ASC',
            field: 'start',
          },
        ],
      };
      const base64 = btoa(JSON.stringify(searchValue));
      url += '?searchRequest=' + base64;
    }
    return this.http.get<Richiesta>(url);
  }

  fetchSurvey() {
    let url = environment.NEW_API + '/requests';
    const searchValue = {
      filter: {
        filterBy: {
          operation: 'AND',
          filters: [
            {
              field: 'surveyRequest',
              operation: 'EQ',
              value: true,
            },
          ],
        },
      },
    };
    const base64 = btoa(JSON.stringify(searchValue));
    url += '?searchRequest=' + base64;
    return this.http.get<Richiesta>(url);
  }
}
