<div class="container">
  <h1 class="do3-fw800 mt-3">
    Contatti
  </h1>
</div>
<div class="super-cont">
  <div class="containe">
    <img src="../../../assets/images/contatti.png" alt="Contatti">
    <h2 class="text-gradient-drfeel">Sempre al tuo fianco</h2>
    <h3>Hai bisogno di aiuto? Siamo qui per te!</h3>
    <p>Il servizio di assistenza è attivo dal lunedì al venerdì dalle ore 9.00 alle ore 18.00</p>
    <a class="email" href="mailto:assistenza@drfeel.com">
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
          fill="#ff3d77" />
      </svg>
      assistenza@drfeel.com
    </a>
    <a class="email" href="https://api.whatsapp.com/send?phone=393757347706" target="_blank">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.779 28.779" height="24" width="24">
        <path id="Icon_ionic-logo-whatsapp" data-name="Icon ionic-logo-whatsapp" d="M16.9,2.25A14.074,14.074,0,0,0,2.773,16.269,13.874,13.874,0,0,0,4.8,23.508L2.25,31.029l7.822-2.485A14.152,14.152,0,0,0,31.029,16.269,14.074,14.074,0,0,0,16.9,2.25Zm7.025,19.343a3.649,3.649,0,0,1-2.5,1.611c-.663.035-.681.514-4.294-1.056s-5.785-5.386-5.956-5.632a6.929,6.929,0,0,1-1.333-3.755,4,4,0,0,1,1.375-2.937,1.384,1.384,0,0,1,.978-.412c.284,0,.469-.008.679,0s.526-.044.8.683.928,2.515,1.012,2.7a.655.655,0,0,1,.007.628,2.453,2.453,0,0,1-.383.583c-.189.2-.4.452-.565.607-.188.171-.384.358-.186.724a10.811,10.811,0,0,0,1.913,2.553,9.857,9.857,0,0,0,2.833,1.883c.354.193.566.172.785-.06s.94-1.012,1.195-1.36.492-.281.819-.15,2.066,1.064,2.42,1.256.591.291.675.443A2.967,2.967,0,0,1,23.926,21.593Z" transform="translate(-2.25 -2.25)" fill="#25D366"></path>
      </svg>
      Whatsapp
    </a>
  </div>
</div>