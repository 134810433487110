<div class="container percorsi my-5">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>
  <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>

  <div id="more-percorsi" *ngIf="!loading">
    <h2 class="fw-bold title">Scopri i nostri percorsi</h2>
    <div class="row row-cols-md-3 g-md-3">
      <!-- Vivi meglio con link -->
      <!-- <div *ngFor="let product of products?.items" class="col-md-4 singolo-percorso" [hidden]="!product.purchasable"> -->
      <div
        *ngFor="let product of products?.items"
        class="col-md-4 singolo-percorso"
      >
        <!-- PATH -->
        <ng-container *ngIf="product.type == 'PATH'; else memberTx">
          <a
            [routerLink]="['/percorsi/', product.code.toLowerCase()]"
            class="d-flex position-relative"
          >
            <img
              [src]="product.images[0].url"
              class="img-fluid"
              alt="{{ product.name }}"
              (error)="onImageError($event)"
            />
            <div
              class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
            >
              <h2>{{ product.name }}</h2>
              <h4>{{ product.code }}</h4>
              <h3>{{ product.description }}</h3>
            </div>
            <div
              *ngIf="!product.purchasable"
              class="position-absolute h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center overlay"
            >
              <span>Coming Soon</span>
            </div>
          </a>
        </ng-container>
        
        <!-- MEMBER -->
        <ng-template #memberTx>
          <a
            [routerLink]="['/membership/', product.code.toLowerCase()]"
            class="d-flex position-relative"
          >
            <img
              [src]="product.images[0].url"
              class="img-fluid"
              alt="{{ product.name }}"
              (error)="onImageError($event)"
            />
            <div
              class="d-flex flex-column ps-4 justify-content-end position-absolute h-100 w-100 top-0 right-0 text-white container-singolo-percorso"
            >
              <h2>{{ product.name }}</h2>
              <h4>{{ product.code }}</h4>
              <h3>{{ product.description }}</h3>
            </div>
            <div
              *ngIf="!product.purchasable"
              class="position-absolute h-100 w-100 top-0 left-0 d-flex justify-content-center align-items-center overlay"
            >
              <span>Coming Soon</span>
            </div>
          </a>
        </ng-template>
      </div>
    </div>
  </div>
</div>
