import { Component, Input, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';
@Component({
  selector: 'app-documenti',
  templateUrl: './documenti.component.html',
  styleUrls: ['./documenti.component.scss'],
})
export class DocumentiComponent implements OnInit, OnDestroy, AfterViewInit {
  private storeSubscription: Subscription;
  public documentsFromStore: ProfiloDocumentDatum[];
  public loading: boolean = true;
  public typeDoc: string = 'diagnosis';
  public filtredDocs: ProfiloDocumentDatum[];
  public currentPage: number = 1;
  public itemsPerPage: number = 5;
  @Input() docType: string;

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(ProfileActions.fetchDocuments());
    this.storeSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.documentsFromStore = profile.documents;
        this.filtredDocs = profile.documents;
        this.loading = profile.loading;
  
        // Assicurati di chiamare la funzione di filtro durante l'inizializzazione
        this.filterAndPaginateDocs();
      });
  }

  private filterAndPaginateDocs() {
    if (this.documentsFromStore) {
      this.filtredDocs = this.filterDocsByType(this.documentsFromStore, this.typeDoc);
      // Assicurati di chiamare questa funzione anche quando la pagina cambia
      this.filtredDocs = this.filtredDocs.slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
    }
  }

  ngAfterViewInit() {
    if (this.docType) {
      this.changeDocs(this.docType);
    }
  }

  // changeDocs(string: string) {
  //   this.typeDoc = string
  //   this.filtredDocs = this.documentsFromStore.filter(doc => doc.documentType === this.typeDoc.toUpperCase())
  //   console.log(this.filtredDocs)
  // }

  changeDocs(string: string) {
    this.typeDoc = string;
    this.currentPage = 1; // Reimposta la pagina corrente a 1
    if (this.documentsFromStore) {
      // Chiamare la funzione di filtro quando il tipo di documento cambia
      this.filterAndPaginateDocs();
    }
  }
  filterDocsByType(docs: any[], type: string): any[] {
    return docs
      .filter((doc) => doc.documentType === type.toUpperCase())
      .slice(
        (this.currentPage - 1) * this.itemsPerPage,
        this.currentPage * this.itemsPerPage
      );
  }
  changePage(pageNumber: number) {
    this.currentPage = pageNumber;
    if (this.documentsFromStore) {
      this.filtredDocs = this.filterDocsByType(this.documentsFromStore, this.typeDoc);
    }
  }
  
  previousPage() {
    if (this.currentPage > 1) {
      this.changePage(this.currentPage - 1);
    }
  }
  
  nextPage() {
    if (this.documentsFromStore) { // Verifica se this.documents non è null
      const totalPages = Math.ceil(
        this.documentsFromStore.filter((doc) => doc.documentType === this.typeDoc.toUpperCase()).length / this.itemsPerPage
      );
      if (this.currentPage < totalPages) {
        this.changePage(this.currentPage + 1);
      }
    }
    console.log(this.filtredDocs);
  }
  
  getTotalPages(): number {
    if (this.documentsFromStore) { // Verifica se this.documents non è null
      const totalDocs = this.documentsFromStore.filter(
        (doc) => doc.documentType === this.typeDoc.toUpperCase()
      ).length;
      return Math.ceil(totalDocs / this.itemsPerPage);
    }
    return 0; // Se this.documents è null, ritorna un valore predefinito o gestisci l'errore in modo appropriato
  }  

  ngOnDestroy(): void {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.docType) {
      this.docType = '';
    }
  }
}
