import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchProducts } from '../dashboard/store/dashboard.actions';
import { Subscription } from 'rxjs';
import { Products } from 'src/app/model/Products';

@Component({
  selector: 'app-percorsi',
  templateUrl: './percorsi.component.html',
  styleUrls: ['./percorsi.component.scss']
})
export class PercorsiComponent implements OnInit, OnDestroy {

  private dashboardSubscribtion: Subscription;
  public products: Products | null;
  public loading: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(fetchProducts());
    this.dashboardSubscribtion = this.store
      .select('dashboard')
      .subscribe((dashboard) => {
        this.products = dashboard.products;
        this.loading = dashboard.loading;
        console.log('products', this.products);
      });
  }

  onImageError(event: any): void {
    event.target.src = 'https://drfeel.com/assets/app-img/percorsi/vivi-meglio/vivi_meglio_uomo_U45.jpg';
  }

  ngOnDestroy(): void {
    this.dashboardSubscribtion && this.dashboardSubscribtion.unsubscribe();
  }
}
