<app-loading-spinner *ngIf="!loadingPolicies"></app-loading-spinner>

<div class="row privacy" *ngIf="loadingPolicies">
  <div class="col-12 mb-5">
    <div class="d-md-none super-margin">
      <h1 class="do3-lightblue-text do3-fw800">Privacy</h1>
      <h2 class="do3-fw600 do3-blue-text">
        Le tue preferenze per il trattamento dei dati personali.
      </h2>
    </div>
    <h1 class="d-none d-md-block do3-fw600 do3-blue-text">
      Le tue preferenze per il trattamento dei dati personali.
    </h1>
  </div>
  <form #f="ngForm" (ngSubmit)="acceptPrivacy(f)">
    <div class="col-12">
      <div
        class="row align-items-center privacy-container"
        *ngIf="policies.data[0]"
      >
        <div class="privacy-item col-md-7 col-12">
          <p>
            Ho letto e compreso la
            <a
              class="do3-fw800"
              target="_black"
              href="https://www.iubenda.com/privacy-policy/54416982/cookie-policy"
            >
              Cookie Policy.
            </a>
            <!-- <span class="do3-fw800">cookie policy</span> -->
            <span *ngIf="policies.data[0].mandatory"> </span>
          </p>
        </div>
        <p class="col-md-3 col-12 data-text">
          Data accettazione:
          {{ policies.data[0].lastUpdate | date : "shortDate" }}
        </p>
        <!-- <div class="col-1">
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  [ngModel]="policies.data[0].accepted"
                  type="checkbox"
                  class="checkbox"
                  name="privacy1"
                  id="privacy1"
                  [required]="policies.data[0].mandatory"
                  [disabled]="policies.data[0].mandatory"
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-1">
          <a target="_black" href="https://www.iubenda.com/privacy-policy/54416982/cookie-policy">
            <mat-icon>link</mat-icon>
          </a>
        </div> -->
      </div>

      <div
        class="row align-items-center privacy-container"
        *ngIf="policies.data[1]"
      >
        <div class="privacy-item col-md-7 col-12">
          <p>
            Ho letto e compreso i
            <a
              class="do3-fw800"
              target="_black"
              href="/assets/docs/DrFeel_TC.pdf"
            >
              Termini e condizioni.
            </a>
            <span *ngIf="policies.data[1].mandatory"> </span>
          </p>
        </div>
        <p class="col-md-3 col-12 data-text">
          Data accettazione:
          {{ policies.data[1].lastUpdate | date : "shortDate" }}
        </p>
        <!-- <div class="col-1">
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  [ngModel]="policies.data[1].accepted"
                  type="checkbox"
                  class="checkbox"
                  name="privacy2"
                  id="privacy2"
                  [required]="policies.data[1].mandatory"
                  [disabled]="policies.data[1].mandatory"
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-1">
          <a target="_black" href="/assets/docs/DrFeel_TC.pdf">
            <mat-icon>link</mat-icon>
          </a>
        </div> -->
      </div>

      <div
        class="row align-items-center privacy-container mb-4"
        *ngIf="policies.data[2]"
      >
        <div class="privacy-item col-md-7 col-12">
          <p>
            Ho letto e compreso la
            <a
              class="do3-fw800"
              target="_black"
              href="/assets/docs/Privacy_Policy_DrFeel.pdf"
            >
              Privacy Policy.
            </a>
          </p>
        </div>
        <p class="col-md-3 col-12 data-text">
          Data accettazione:
          {{ policies.data[2].lastUpdate | date : "shortDate" }}
        </p>
        <!-- <div class="col-1">
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  [ngModel]="policies.data[2].accepted"
                  type="checkbox"
                  class="checkbox"
                  name="privacy3"
                  id="privacy3"
                  [required]="policies.data[2].mandatory"
                  [disabled]="policies.data[2].mandatory"
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-1">
          <a target="_black" href="/assets/docs/Privacy_Policy_DrFeel.pdf">
            <mat-icon>link</mat-icon>
          </a>
        </div> -->
      </div>
    </div>
    <!-- <button
      type="submit"
      class="do3-secondary-btn light-blue ml-0"
      [disabled]="!f.touched"
    >
      SALVA MODIFICHE
    </button> -->
  </form>
</div>
