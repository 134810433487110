<div class="document-box">
  <div class="row align-items-center">
    <div class="col-11">
      <div class="row align-items-center">
        <div class="col-md-4 col-12 blue-mobile d-flex align-items-center gap-3">
          <div class="d-none d-md-block">
            <div class="box" [ngClass]="parseDocType(doc.contentType)">
              <div class="box-content">
                {{ parseDocType(doc.contentType) }}
              </div>
            </div>
          </div>
          <!-- {{ doc.name | slice : 0 : 25 }} -->
          {{ doc.name.length > 25 ? (doc.name | slice: 0:25) + '...' : doc.name }}

        </div>
        <!-- <div class="col-md-3 col-12">
          <span *ngIf="isMobile">Dimensione:</span>
          {{ parseBytes(doc.bytes) }}
        </div> -->
        <div class="col-md-4 col-12 blue-mobile">{{ doc.author.name }}</div>
        <div class="col-md-4 col-12">
          {{ doc.dateTime | date : "d MMMM y" }}
        </div>
      </div>
    </div>

    <div class="col-1 d-flex align-items-center">
      <div (click)="downloadDocument(doc)">
        <mat-icon class="do3-lightblue-text"> save_alt </mat-icon>
      </div>
      <!-- <mat-icon class="dots">more_vert</mat-icon> -->
    </div>
  </div>
</div>