<div class="container do3-gray-text">
  <div class="termini-condizioni row">
    <div class="offset-lg-1 col-lg-10">

      <div class="text-card">
        <h2 class="do3-fw900">Termini e condizioni d’uso Pazienti </h2>
        <h2 class="do3-fw900">1. Ambito di applicazione.</h2>
        <p>
          Questi termini e condizioni si applicano all’accordo tra Lab46 S.r.l., P.IVA 08626121217, (la “Società”) e il
          paziente (“Utente”) che registra un account (“Account”) sul sito Web Dottorionline.com (di seguito il “Sito”)
          messo a disposizione dalla Società per ricevere servizi di assistenza sanitaria da personale medico
          qualificato
          (definito come “Medico”). Ai fini del presente documento si precisa che:
        </p>
        <ul>
          <li>
            il “Videoconsulto” è un atto sanitario in cui il Medico interagisce a distanza con il paziente, con l’eventuale
            supporto di un caregiver, che può dar luogo alla prescrizione di farmaci o di cure. Un operatore sanitario
            che
            si trovi vicino al paziente può assistere il Medico durante il Videoconsulto
          </li>
          <li>
            il “Teleconsulto” è un’indicazione di diagnosi e/o di scelta di una terapia senza la presenza fisica del
            paziente. Si tratta di un’attività di consulenza a distanza che permette a un medico di chiedere consiglio
            ad
            uno o più medici, in ragione della specifica formazione e competenza, sulla base di informazioni mediche
            legate alla presa in carico del paziente.

            <ul class="list-no-style type1">
              <li>
                1.1 Attraverso la piattaforma, gli Utenti possono prenotare ed eseguire consultazioni con Medici. I
                servizi (da qui in poi “SERVIZI) possono essere prenotati e / o erogati attraverso le funzionalità di
                comunicazione audio, video, condivisione di contenuti, messaggistica integrata della piattaforma (queste
                consultazioni da remoto possono dare luogo a “televisite”, “teleconsulti” o “consulenze a distanza” così
                come definiti al punto 1.1.). L’Utente descrive la sua condizione al Medico il quale, dopo aver eseguito
                una valutazione del paziente, può fare una diagnosi della problematica e suggerire un trattamento che
                può
                includere o meno la prescrizione di farmaci, esami di laboratorio o visite specialistiche. Al fine di
                supportare il Medico da remoto, la piattaforma inoltre offre alcune funzionalità aggiuntive quali: una
                chat real-time per connettere Utente e Medico/i, cartella medica elettronica, il telemonitoraggio, la
                possibilità di generare prescrizioni mediche elettroniche, e servizi di prenotazione, fatturazione
                elettronica e pagamento integrati. L’Utente si dichiara a conoscenza dei rischi connessi alla mancanza
                del
                contatto fisico e dello sguardo clinico del Medico, dell’impossibilità di un consulto completo e di un
                intervento immediato fisico in caso di urgenza.
              </li>
              <li>
                1.2 La Società fornisce solo un servizio di intermediazione tra l’Utente ed il Medico e non deve
                pertanto
                essere considerata un professionista. La Società svolge un servizio finalizzato a far incontrare la
                richiesta di prestazione medica specialistica da parte degli Utenti con i medici che offrono tali
                prestazioni ed aderiscono al Sito. L’accordo tra l’Utente e la Società dà luogo quindi esclusivamente ad
                un contratto di servizio relativo alla fornitura di una soluzione tecnica. La Società non è quindi
                responsabile della natura o della qualità dei consulti o di altri servizi medici forniti nel contesto di
                un consulto. In base alla normativa vigente, la Società opera esclusivamente come fornitore della
                piattaforma che permette l’intermediazione tra il Medico e pazienti, e non agisce mai come “Erogatore
                del
                servizio sanitario”.

              </li>
              <li>
                1.3 La Società offre la possibilità agli Utenti di prenotare una prestazione specialistica e/o un esame
                diagnostico. La Società non svolge alcun tipo di attività medica, né è in alcun modo coinvolta nella
                fase
                successiva a quella di prenotazione e, pertanto, non è in alcun modo coinvolta nella negoziazione fra
                Medici e Utenti e tantomeno nello svolgimento della prestazione prenotata per mezzo del Sito. Pertanto,
                la
                Società non può essere in alcun modo considerata responsabile di eventuali danni subiti dall’Utente a
                seguito della prestazione o della mancata prestazione prenotata per mezzo del Sito o di ritardi da parte
                del medico nell’erogazione della prestazione. Chi consulta ed utilizza il Sito si assume tutti i rischi
                relativi al rapporto con i Medici. La Società non deve essere considerata quale Medico rispetto ai
                servizi
                offerti sul Sito. La Società, inoltre, non è in alcun modo responsabile delle decisioni prese dai Medici
                che utilizzano il Sito. La Società non è coinvolta e non può essere coinvolta nei rapporti tra Utenti e
                Medici anche se gli stessi hanno origine dal Sito; perciò, nel caso in cui dovessero sorgere delle
                controversie tra Utenti e Medici, la Società non sarà ritenuta responsabile per nessun danno l’Utente
                dovesse subire in conseguenza dell’operato dei Medici.

              </li>
              <li>
                1.4 Il soggetto che intende avvalersi dei servizi erogati attraverso la piattaforma è l’Utente, il quale
                stipula un accordo con la Società creando un account in conformità con le attuali linee guida della
                Piattaforma.

              </li>
              <li>
                1.5 Registrando un Account nella Piattaforma, l’Utente accetta i termini e le condizioni d’uso qui
                delineati.

              </li>
              <li>
                1.6 Il Paziente prende atto e dichiara di essere pienamente consapevole che:
                <ul class="list-no-style no-space type2">
                  <li>
                    a) il consulto a distanza non è sostitutivo della visita effettuata dal Medico alla presenza del
                    Paziente;
                  </li>
                  <li>
                    b) la mancanza della visita limita la possibilità per il Medico di ottenere informazioni complete
                    sulle condizioni cliniche del Paziente;
                  </li>
                  <li>
                    c) le indicazioni cliniche fornite con il Consulto a distanza si basano esclusivamente sulle
                    informazioni rese disponibili dal Paziente;
                  </li>
                  <li>
                    d) il Medico, il SSN ed il gestore della piattaforma di Consulto non avranno alcuna responsabilità
                    sull’accuratezza e/o completezza delle informazioni ricevute dal Paziente;
                  </li>
                  <li>
                    e) il consulto a distanza non è uno strumento adatto a gestire situazioni di emergenza e non
                    sostituisce una visita del Medico alla presenza del Paziente;
                  </li>
                  <li>
                    f) in caso di emergenza, il Paziente deve immediatamente chiamare il 118 o recarsi in Pronto
                    Soccorso;
                  </li>
                  <li>
                    g) un eventuale ritardo nella fornitura del Consulto come anche l’impossibilità di accedere al
                    servizio per ragioni tecniche di qualsiasi natura non comporta responsabilità per il Medico per le
                    conseguenze che possano derivare al Paziente da tale situazione.
                  </li>
                  <li>
                    h) il SSN non potrà in alcun modo essere ritenuto responsabile dei ritardi o malfunzionamenti della
                    Piattaforma dovuti a circostanze imprevedibili, nonché al di fuori del proprio controllo o a cause
                    di
                    forza maggiore tra cui, in via meramente esemplificativa e non esaustiva ed anche alternativa se del
                    caso, calamità naturali, avverse condizioni atmosferiche, sabotaggi, incendi, alluvioni, terremoti,
                    scioperi di carattere nazionale, provvedimenti di legge e/o ordini di autorità pubbliche e
                    giudiziarie, difettoso funzionamento della Piattaforma conseguente all’interruzione o al non
                    corretto
                    funzionamento di piattaforme di proprietà di terzi e/o di connessioni web, delle linee telefoniche,
                    elettriche e di reti mondiali e/o nazionali, quali, a mero fine esemplificativo e non esaustivo,
                    guasti, sovraccarichi, interruzioni.
                  </li>
                </ul>
              </li>
              <li>
                1.7 L’Utente è responsabile di garantire che tutti i dati personali forniti al momento dell’adesione
                alla
                Società siano pertinenti, corretti e aggiornati per tutta la durata del contratto. Eventuali modifiche
                potenziali a queste informazioni devono essere segnalate alla Società senza indugio, tramite e-mail
                privacy@dottorionline.com.

              </li>
              <li>
                1.8 Prenotando un SERVIZIO, il Paziente instaura una relazione contrattuale direttamente con il Medico
                per
                lo svolgimento del SERVIZIO, con o senza pagamento. La Società non è parte dell’accordo tra il Paziente
                e
                Medico per lo svolgimento del SERVIZIO.

              </li>
              <li>
                1.9 La disponibilità dei SERVIZI dipende dalla disponibilità individuale dei Medici, come indicato sulla
                Piattaforma.

              </li>
              <li class="indent30">
                1.10 Qualora il collegamento con il Medico non risulti tecnicamente possibile, o emerga qualsiasi tipo
                di
                difficoltà prima o durante il SERVIZIO o qualora il caricamento della Piattaforma per il collegamento
                richieda troppo tempo, l’Utente è invitato a rivolgersi direttamente ad un centro medico o ad altri
                servizi di assistenza sanitaria o professionale, specialmente in casi di urgenza.
              </li>
              <li class="indent30">
                1.11 I SERVIZI NON DEVONO ESSERE UTILIZZATI dai Pazienti che:
                <ul class="list-no-style no-space type2">
                  <li>
                    a) Abbiano bisogno di trattamento ospedaliero;
                  </li>
                  <li>
                    b) soffrono di sintomi gravi che possano comportare un pericolo imminente per la vita o lo stato di
                    salute (a mero titolo esemplificativo: Pazienti non coscienti, Pazienti con reazioni allergiche
                    gravi,
                    Pazienti con emorragie incontrollate), o che esperiscono uno o più dei seguenti sintomi o che
                    abbisognano di assistenza medica in relazione, ad esempio, ad una delle seguenti problematiche:
                    <p class="like-list">
                      • improvviso forte dolore toracico <br>
                      • aritmie <br>
                      • improvviso fiato corto <br>
                      • improvviso forte dolore allo stomaco <br>
                      • vomito persistente <br>
                      • avvelenamento da droghe, agenti chimici o gas <br>
                      • ustioni intense <br>
                      • colpi di sole <br>
                      • corpo freddo <br>
                      • cadute dall’alto <br>
                      • incidenti stradali <br>
                      • scosse elettriche <br>
                      • annegamento <br>

                    </p>
                  </li>
                </ul>
              </li>
              <li class="indent30">
                1.12 Questi termini e condizioni d’uso, nonché le istruzioni, le politiche e le informazioni aggiuntive
                relative al servizio sono disponibili sul sito dottorionline.com
              </li>
            </ul>
          </li>
        </ul>

        <h2 class="do3-fw900">2. Informazioni generali sugli account e la piattaforma .</h2>
        <ul class="list-no-style type1">
          <li>
            2.1 Nel momento in cui l'Utente accede alla procedura di registrazione sul Sito, fornirà alla Società alcuni
            dati personali (di seguito i "Dati Personali"). In merito al trattamento dei Dati Personali da parte della
            Società, l'Utente è pregato di fare riferimento alla Privacy Policy di dottorionline.com presente sul Sito.
            A
            tal riguardo, si fa presente che il consenso al trattamento dei Dati Personali, per finalità connesse ai
            servizi forniti sul Sito, costituisce requisito necessario per la registrazione presso dottorionline.com e
            per
            la fruizione dei servizi offerti dal Sito.
          </li>
          <li>
            2.2 L’Utente è responsabile delle conseguenze che possono derivare dall'inserimento di dati, informazioni e
            materiale di ogni genere sul Sito. L'Utente si obbliga a: <br>
            <p class="like-list">
              <br>
              • non inserire materiale che violi la privacy altrui; <br>
              • non falsificare la propria identità; <br>
              • non caricare, inviare via e-mail, o in altro modo trasmettere, qualsivoglia materiale che contenga
              virus,
              Trojan horses, worms, time bombs, cancelbots o altri codici, file o programmi creati per interrompere,
              distruggere o limitare il funzionamento dei software, degli hardware o degli impianti di telecomunicazione
              di terzi; <br>
              • non utilizzare il Sito o il Materiale (così come definito al punto 3) in esso inserito per perseguire
              scopi illegali ovvero quale strumento per divulgare o diffondere in qualsiasi modo materiale o contenuti
              preordinati alla commissione di attività illecite; <br>
              • non utilizzare il Sito per la trasmissione o il collocamento di qualsivoglia materiale diffamatorio,
              offensivo, osceno o minaccioso o che in qualche modo possa creare fastidio, disturbo o qualsivoglia
              pregiudizio. <br>
            </p>
            Conseguentemente l’Utente terrà indenne la Società da e contro ogni responsabilità e conseguenza
            pregiudizievole, incluse senza eccezioni le spese legali sostenute dalla Società in conseguenza di azioni
            legali derivanti da violazioni da parte dell’Utente dei predetti obblighi. <br> <br>
            La Società si riserva il diritto di espellere gli Utenti e di proibire il successivo accesso degli stessi al
            Sito, laddove questi ultimi si siano resi responsabili di violazioni dei termini contrattuali o di norme di
            legge.
          </li>
          <li>
            2.3 L’adesione al contratto, la registrazione di un Account e l’accesso alla Piattaforma sono possibili solo
            a
            condizione che il paziente disponga di un codice fiscale personale valido ed abbia compiuto diciotto (18)
            anni
            di età.
          </li>
          <li>
            2.4 L’utente è responsabile di tutte le azioni relative all’utilizzo dell’account. L’account può essere
            utilizzato solo dall’Utente a cui l’Account è registrato e non può essere utilizzato da, o trasferito a,
            un’altra persona. L’unica eccezione a questa regola riguarda i tutori legali che possono utilizzare il
            proprio
            account per conto di un minore, a condizione che il minore abbia meno di diciotto anni (18). La Società non
            si
            assume alcuna responsabilità per eventuali abusi da parte di terzi o eventuali conseguenze o potenziali
            danni
            che potrebbero derivare da tale uso improprio.
          </li>
          <li>
            2.5 L’utente è responsabile di assicurare che l’account sia utilizzato in conformità con i presenti termini
            e
            condizioni d’uso, nonché con le altre condizioni comunicate nella piattaforma per la durata dell’accordo.
            L’utente è l’unico responsabile delle informazioni che invia nella Piattaforma e, di conseguenza, si assume
            ogni responsabilità per il suo contenuto.
          </li>
          <li>
            2.6 Registrando un account nella Piattaforma, l’utente conferma e accetta le specifiche tecniche, le
            condizioni e i limiti della Piattaforma che sono descritti nel servizio e nelle descrizioni dei prodotti
            della
            Società, disponibili nella Piattaforma.
          </li>
          <li>
            2.7 Aprendo un Account nella Piattaforma, l’utente accetta e conferma che i dati di contatto forniti possono
            essere utilizzati dalla Società per inviare offerte e informazioni relative al servizio tramite e-mail e
            notifiche push. L’utente ha il diritto di annullare questa forma di comunicazione in qualsiasi momento dopo
            la
            registrazione dell’account. La Società è responsabile per dare esecuzione a tale richiesta senza ritardo.
          </li>
          <li>
            2.8 Per (i) revocare il consenso al trattamento o (ii) cancellare tutti i dati personali scrivere a e-mail
            privacy@dottorionline.com
          </li>
        </ul>
        <h2 class="do3-fw900">3. Materiale </h2>
        <ul class="list-no-style type1">
          <li>
            3.1 Il testo, la grafica, le immagini, i loghi, le icone, il software e ogni altro materiale presente sul
            Sito
            (di seguito il "Materiale") godono di protezione giuridica ai sensi delle leggi e dei regolamenti in materia
            di proprietà intellettuale ed industriale sia italiane che internazionali. L'uso non autorizzato del
            Materiale
            può pertanto costituire violazione della normativa in materia di proprietà intellettuale.
          </li>
          <li>
            3.2 L’Utente non è autorizzato a vendere, modificare, riprodurre, esporre, utilizzare pubblicamente,
            distribuire, o comunque impiegare il Materiale in qualsivoglia altro modo, a fini commerciali o comunque
            pubblici. L'Utente si impegna altresì a non sfruttare a fini commerciali i servizi offerti sul Sito o
            l'accesso allo stesso.
          </li>
          <li>
            3.3 Non sono in alcun modo consentite la copia o comunque la modifica del codice HTML, creato dalla Società
            per la realizzazione delle pagine del Sito. Il codice stesso è protetto dal diritto d'autore della Società.
            L’utente deve astenersi dal cercare di ottenere l’accesso non autorizzato a qualsiasi parte o funzionalità
            del
            sito, o ad altri sistemi o network collegati al Sito, mediante tecniche di hacking, intercettazione delle
            password o qualsiasi altro mezzo illegale.
          </li>
          <li>
            3.4 È fatto divieto di sondare, esaminare o testare la vulnerabilità del Sito o di qualsiasi rete a esso
            collegata, nonché di violarne la sicurezza o le misure di autenticazione.
          </li>
          <li>
            3.5 Non è consentito effettuare operazioni di reverse lookup, tracciare o cercare di tracciare l’origine di
            informazioni relative a qualsiasi utente o visitatore del Sito o qualsiasi cliente; è altresì proibito
            sfruttare il Sito o i servizi e le informazioni resi disponibili o offerti tramite il Sito allo scopo di
            rivelare qualsiasi informazione relativa a terzi, inclusi senza limitazione alcuna i dati di identificazione
            personale.
          </li>
          <li>
            3.6 Il Materiale presente sul sito può contenere imprecisioni o errori di stampa. La Società non garantisce
            l'accuratezza, l'attendibilità, la completezza o la tempestività delle informazioni contenute nel Sito o nel
            Materiale. La Società agisce come semplice interfaccia per la distribuzione e pubblicazione on-line delle
            informazioni e dei dati inseriti e non ha alcun obbligo di valutare anticipatamente le comunicazioni, i dati
            e
            le informazioni inserite.
          </li>
        </ul>

        <h2 class="do3-fw900">4. Uso del Sito </h2>
        <ul class="list-no-style type1">
          <li class="mb-0">
            4.1 Il Sito può essere utilizzato soltanto per scopi personali e non commerciali e per gli usi consentiti
            dalla legge da soggetti alla ricerca di visita medica specialistica e/o esami diagnostici. La Società
            proibisce le seguenti modalità di utilizzo del Sito:
          </li>
          <p class="like-li">
            • Inserimento di informazioni personali incomplete, false, imprecise;
          </p>
          <p class="like-li">

            • Cancellazione o modifica di Materiale (come definito al punto 3);
          </p>
          <p class="like-li">
            • Utilizzo di qualsiasi dispositivo, software o routine che possa interferire con la funzionalità del Sito o
            comunque con qualunque attività condotta sul Sito stesso;
          </p>
          <p class="like-li">
            • Intraprendere qualunque azione che imponga un irragionevole o sproporzionato carico sull'infrastruttura
            del
            Sito;
          </p>
          <p class="like-li">
            • Rivelare o condividere la password con terzi o utilizzare la password per qualunque fine non autorizzato;
          </p>
          <p class="like-li">
            • Salvo quanto diversamente previsto nelle presenti Condizioni, usare o cercare di utilizzare motori di
            ricerca, software o altri dispositivi o meccanismi (inclusi, senza alcuna limitazione, browsers, spiders,
            robots, avatars o intelligent agents) per navigare o esplorare questo Sito; Cercare di decifrare,
            decodificare, smontare o ricostruire il contenuto del software utilizzato per strutturare il Sito. <br>
          </p>
          <br>
          <p class="no-ident">
            Gli Utenti, in generale, non devono violare o comunque tentare di violare il sistema di sicurezza del Sito.
            Le
            violazioni delle misure di sicurezza del sistema possono comportare responsabilità civile o penale. La
            Società
            provvederà pertanto ad informare le competenti Autorità relativamente a quei comportamenti che potrebbero
            risultare in violazione di leggi penali o civili e potrà, ove richiesto, cooperare con le competenti
            Autorità
            nel perseguire gli Utenti coinvolti in tali violazioni.
          </p>
          <br>
          <p class="no-ident">
            L'Utente si impegna a non rivendere o cedere i propri diritti od obbligazioni derivanti dalle presenti
            Condizioni. L'Utente ribadisce il proprio impegno a non fare un uso commerciale non autorizzato del Sito.
          </p>
        </ul>

        <h2 class="do3-fw900">5. Tariffe e pagamento </h2>
        <ul class="list-no-style type1">
          <li>
            5.1 La registrazione alla piattaforma è gratuita
          </li>
          <li>
            5.2 Le tariffe per un consulto con il Medico sono specificate in fase di richiesta del consulto. La Società,
            in veste di operatore tecnologico di supporto all’interazione tra Medico e paziente, utilizzerà la propria
            tecnologia per raccogliere il corrispettivo dovuto dal paziente per conto del Medico.
          </li>
          <li>
            5.3 Dopo aver inviato la richiesta di consulto:
            <ul class="list-no-style no-space type2">
              <br>
              <li>
                a) gli Utenti non possono avviare un consulto con il Medico prima che il metodo di pagamento sia stato
                registrato e il pagamento sia stato precedentemente approvato;
              </li>
              <li>
                b) la Piattaforma può offrire condizioni speciali di prezzo o di pagamento attraverso l’uso di codici di
                sconto o promozioni;
              </li>
              <li>
                c) una volta prenotato un SERVIZIO il sistema verificherà automaticamente la presenza di fondi sul
                metodo
                di pagamento selezionato e bloccherà l’ammontare equivalente alla tariffa fino al momento del
                completamento del consulto o cancellazione di questo (per dettagli sulle modalità di addebito in caso di
                cancellazione o modifica della prenotazione fare riferimento al capitolo dedicato)
              </li>
            </ul>
          </li>
        </ul>
        <h2 class="do3-fw900">6. La responsabilità della Società </h2>
        <span>
          Con le limitazioni descritte in questo articolo (6), la Società è responsabile di assicurare che la
          piattaforma
          sia disponibile ai sensi degli articoli qui di seguito.
        </span>
        <ul class="list-no-style type1">

          <li>
            6.1 La Società è responsabile di garantire la conformità della Piattaforma alla normativa vigente in materia
            di fornitura di servizi a distanza. La Società non si assume alcuna responsabilità per la perdita di dati
            nei
            limiti previsti dalla vigente normativa.
          </li>
          <li>
            6.2 La Società non è responsabile per i contenuti distribuiti tramite la piattaforma.
          </li>
          <li>
            6.3 La Società mira a garantire la continua disponibilità e fruibilità della piattaforma. L’Utente dovrebbe
            avere una ragionevole possibilità di visitare l’Account e la Piattaforma in qualsiasi momento della
            giornata.
            L’Utente può prenotare un appuntamento per una data e ora futura, o richiedere un consulto con il primo
            erogatore dei servizi sanitari disponibile (consulto “drop-in”). La Piattaforma, incluse i consulti, viene
            fornita in conformità con i termini e le condizioni di cui sopra, ad eccezione delle interruzioni consentite
            come a titolo esemplificativo la manutenzione pianificata della Piattaforma o del sistema di servizio.
          </li>
          <li>
            6.4 Il tempo di attesa e tutte le altre stime temporali qualora fornite e / o pubblicate sulla piattaforma
            si
            basano sulle stime del sistema. L’Utente accetta che il tempo di attesa e tutte le altre stime temporali non
            garantiscono la fornitura dei servizi entro un determinato periodo di tempo o in toto. L’Utente accetta che
            la
            Società non rimborserà le tariffe in base ai tempi di attesa o altre stime di tempo.
          </li>
          <li>
            6.5 Se l’Utente decide di richiedere il primo Medico disponibile (senza quindi specificarne uno), sarà il
            primo Medico professionista disponibile a prendere in carico la richiesta del paziente, filtrato a seconda
            dei
            parametri espressi dall’Utente elaborati dagli algoritmi della SOCIETÀ.
          </li>
          <li>
            6.6 La Società non si assume alcuna responsabilità per interruzioni della disponibilità risultanti da:
          </li>
          <p class="like-li">
            • errori / problemi con l’hardware / attrezzatura dell’utente, rete, software o errori nel software che fa
            parte di un prodotto di terze parti e che la Società, nonostante i tentativi di prevenire e correggere, non
            può evitare;
          </p>
          <p class="like-li">
            • altre circostanze di cui l’Utente è responsabile secondo il presente contratto;
          </p>
          <p class="like-li">
            • virus sul dispositivo utilizzato o altra minaccia alla sicurezza che, nonostante gli sforzi preventivi
            della
            Società interrompano il servizio;
          </p>
          <p class="like-li">
            • circostanze che costituiscono cause di forza maggiore ai sensi dell’articolo 14 di seguito.
          </p>
          <li>
            6.7 La Società è responsabile solo per il funzionamento della Piattaforma, che comprende la fornitura dei
            SERVIZI tra l’Utente ed i Medici. La Società non è responsabile per l’assistenza sanitaria, le informazioni
            o
            le raccomandazioni fornite dal Medico all’Utente per iscritto o tramite gli strumenti di comunicazione
            forniti
            dalla piattaforma.
          </li>
          <li>
            6.8 Eventuali interruzioni o errori nella disponibilità della Piattaforma devono essere segnalati senza
            indugio al servizio clienti della Società all’indirizzo supportotecnico@dottorionline.com
          </li>
          <li>
            6.9 In primo luogo, gli errori verranno corretti tramite il recupero degli errori, se ciò può essere fatto
            senza costi o disagi irragionevoli per la Società. La Società si riserva il diritto di rettificare l’errore
            a
            proprie spese a condizione che ciò possa essere fatto senza costi o inconvenienti irragionevoli per l’Utente
          </li>
          <li class="indent30">
            6.10 La Società non è responsabile per gli errori che si verificano a causa dell’Utente o qualsiasi
            condizione
            che è responsabilità dell’Utente. Inoltre, la Società non è responsabile per eventuali danni o conseguenze
            personali che potrebbero derivare, direttamente o indirettamente, dall’uso o dall’uso improprio delle
            informazioni fornite, presentate o dirette dalla Piattaforma. La responsabilità della Società è in ogni caso
            limitata al danno diretto comprovato e il risarcimento è limitato a una somma massima di 300 (trecento)
            euro.
            La Società, pertanto, non è responsabile per danni consequenziali, mancato guadagno o risparmio previsto e/o
            altri danni indiretti.
          </li>
          <li class="indent30">
            6.11 La Società non potrà in alcun modo essere ritenuta responsabile per quanto segue:
            <ul class="list-no-style no-space type2">
              <br>
              <li>
                a) atti, fatti, condotte, negligenze, imprudenze o imperizie del Medico nell’esercizio della propria
                attività professionale, ovvero in qualunque altro caso in cui il Medico professionista operi in totale
                autonomia e secondo la propria responsabilità; la piattaforma si limita infatti ad agevolare il rapporto
                tra il Medico ed il paziente, promuovendo l’incontro online delle domande dei pazienti e delle risposte
                degli operatori sanitari.
              </li>
              <li>
                b) atti, fatti o condotte non appropriate da parte del paziente durante l’utilizzo del servizio.
              </li>
              <li>
                c) eventuali disservizi, interruzioni, sospensione dei servizi, della rete, dei flussi di comunicazione
                e
                qualsivoglia altro mancato funzionamento della piattaforma, dovuti a cause di forza maggiore non
                imputabili alla Società e indipendenti dalla sua volontà
              </li>
              <li>
                d) in ogni caso, alle prestazioni rese dalla Società ai sensi dei presenti termini e condizioni del
                servizio, devono intendersi applicate le normative vigenti di legge, con le relative esenzioni di
                responsabilità ivi previste e disciplinate, a favore della Società che sarà considerata, a seconda della
                specifica prestazione nel caso concreto, mere conduit, caching provider o hosting provider. La Società
                non
                presta alcuna garanzia in ordine alla veridicità e correttezza delle informazioni fornite dal Medico e
                declina qualsiasi responsabilità per danni a persone o a cose derivanti dall’utilizzo diretto o
                indiretto
                di qualsiasi informazione o suggerimento ottenuto utilizzando i servizi offerti dalla Società e
                raccomanda
                ai pazienti di consultare il proprio medico ogni volta che lo si ritenga necessario od opportuno. I
                Medici, da parte loro, sono i soli responsabili, nei confronti del paziente, per le informazioni che
                forniranno attraverso i servizi offerti. La Società non svolge alcun tipo di attività medica e non è in
                alcun modo coinvolta nella interazione fra i Medici e pazienti e tantomeno nello svolgimento di
                un’eventuale prestazione che dovesse aver luogo successivamente al consulto on-line, anche se gli stessi
                hanno origine dal consulto on-line. La Società si limita a fornire la piattaforma tecnologica che
                permette
                agli utenti di ottenere contenuti informativi e ad entrare in contatto con i professionisti attraverso
                un
                servizio di messaggistica e video consulenza.
              </li>
            </ul>
          </li>
        </ul>

        <h2 class="do3-fw900">7. Clausola di manleva </h2>
        <span>
          L'Utente dichiara e garantisce di manlevare e tenere indenne la Società, i suoi rappresentanti e i suoi
          dipendenti da qualsiasi obbligo che possa originarsi a loro carico in relazione all'utilizzo del Sito e del
          Materiale da parte dell'Utente stesso o alla violazione delle presenti Condizioni D'Uso.
        </span>

        <h2 class="do3-fw900">8. Diritti di proprietà intellettuale </h2>
        <ul class="list-no-style type1">
          <li>
            8.1 Resta inteso che i diritti di proprietà intellettuale e tutti i diritti relativi al marchio, alla ditta
            e
            alla piattaforma della Società, nonché tutti i documenti degli accordi utilizzati e/o forniti dalla Società
            sulla Piattaforma appartengono esclusivamente alla Società. La Società si riserva quindi il diritto
            esclusivo
            di utilizzare il materiale sopra menzionato. Tutte le repliche, modifiche e/o altro uso del materiale della
            Società che non è stato esplicitamente autorizzato in questi Termini e Condizioni o le istruzioni della
            Società altrove sono severamente vietati. L’Utente conferma e accetta che l’uso non autorizzato dei diritti
            immateriali della Società, oltre a violare il contratto dei Termini e Condizioni, costituisce un atto
            criminale. La Società si riserva il diritto di esercitare il diritto di esercitare azioni legali in caso di
            utilizzo non autorizzato dei diritti immateriali della Società.
          </li>
          <li>
            8.2 Eventuali diritti immateriali derivanti dalla fornitura della Piattaforma della Società e/o altri
            servizi
            all’utente appartengono esclusivamente alla Società. Tale esclusività include il diritto di modificare e
            trasferire tali diritti.
          </li>
        </ul>

        <h2 class="do3-fw900">9. Annullamento di un appuntamento e appuntamenti mancati</h2>
        <ul class="list-no-style type1">
          <li>
            9.1 L’utente può annullare/modificare un appuntamento programmato fino a quarantotto (48) ore prima
            dell’inizio del consulto. Se la cancellazione avviene in conformità con queste linee guida, all’utente non
            verrà addebitato il costo del consulto a distanza. In ogni altro caso, all’utente verrà addebitato la metà
            del
            costo del consulto.
          </li>
          <li>
            9.2 L’Utente ha la possibilità di richiedere il rimborso del pagamento effettuato in modalità elettronica
            attraverso la propria area personale su dottorionline.com. Se l’Utente richiede il rimborso almeno 48 ore
            prima della visita, dottorionline.com procede automaticamente al rimborso integrale dell’importo versato. Se
            invece l’Utente richiede il rimborso a meno di 48 ore dalla visita, il rimborso avverrà solo se il
            Professionista sarà d’accordo. In caso contrario, l’Utente potrà rivolgersi direttamente al customer care di
            dottorionline.com, che medierà tra l’Utente ed il Professionista e valuterà la possibilità di un rimborso.
          </li>
          <li>
            9.3 Nel caso in cui l’utente non si colleghi per un appuntamento entro dieci (10) minuti dall’orario
            programmato o dalla notifica di inoltro, l’appuntamento sarà considerato “mancato” e all’utente verrà
            addebitato il costo del consulto. Nel caso in cui l’utente abbia problemi tecnici strettamente connessi alla
            Piattaforma al momento del consulto, deve informare la Società per iscritto all’indirizzo e-mail
            supportotecnico@dottorionline.com entro trenta (30) minuti dal momento del consulto. Dopo le verifiche
            effettuate, se il problema viene confermato, la Società rimborserà il costo del consulto.
          </li>
          <li>
            9.4 Nel caso un appuntamento sia stato prenotato come “appena possibile” ed accettata dal Medico, non sarà
            più
            possibile cancellare la prenotazione gratuitamente e l’utente sarà addebitato per il costo del consulto.
          </li>
        </ul>

        <h2 class="do3-fw900">10. Recensioni</h2>
        <ul class="list-no-style indent30">
          <li>
            10.1 Le recensioni devono essere state rilasciate in seguito a prestazioni prenotate esclusivamente tramite
            dottorionline.com e regolarmente effettuate. Se per qualunque motivo la prestazione non è stata effettuata,
            non sarà possibile rilasciare un commento sul Medico; questo è l’unico modo per assicurare che le recensioni
            siano attendibili ed utili per gli altri utenti.
          </li>
          <li>
            10.2 Il linguaggio deve essere appropriato, non saranno pubblicate recensioni con linguaggio volgare,
            minaccioso o comunque non rispettoso dell’onorabilità del medico o del suo staff;
          </li>
          <li>
            10.3 I commenti devono riguardare la cortesia, la puntualità e la disponibilità del medico. Non saranno
            accettati commenti che mettono in discussione le competenze professionali del medico, poiché non è in questa
            sede che possono essere discusse, anche soprattutto da persone che non hanno competenze mediche specifiche;
          </li>
          <li>
            10.4 Per ciascuna prestazione effettuata, l’utente può pubblicare una sola recensione. Si può richiedere che
            vengano rimosse;
          </li>
          <li>
            10.5 Non si accettano contenuti non pertinenti alle finalità del servizio, che è quella di esprimere la
            propria esperienza sul servizio e sul professionista, secondo i criteri sopra esposti;
          </li>
          <li>
            10.6 Non si accettano recensioni che riportino esperienze o pareri di terzi;
          </li>
          <li>
            10.7 La recensione deve essere riferita alla singola prestazione, non deve fare riferimento ad esperienze
            precedenti anche se prenotate sempre tramite dottorionline.com;
          </li>
          <li>
            10.8 Non saranno pubblicati giudizi che contengano informazioni sensibili, o che potrebbero avere
            conseguenze
            legali: per problemi di questo tipo preghiamo di contattare gli amministratori del sistema.
          </li>
        </ul>

        <h2 class="do3-fw900">11. Uso e informazioni non autorizzati</h2>
        <ul class="list-no-style indent30">
          <li>
            11.1 La Società prende sul serio tutte le forme di utilizzo non autorizzato dell’account e della piattaforma
            in violazione delle presenti condizioni generali e/o della politica aziendale in materia di sicurezza,
            etica,
            ecc. È, tra le altre cose, vietato registrare qualsiasi consultazione con il personale sanitario o in alcun
            modo distribuire qualsiasi materiale relativo ad esso. La Società perseguirà proattivamente un’azione contro
            l’uso non autorizzato confermato o anticipato dell’account, della piattaforma, ecc.
          </li>
          <li>
            11.2 La Società si riserva il diritto, con preavviso o notifica all’utente, di rimuovere le informazioni
            dalla
            Piattaforma, chiudere l’Account dell’utente o perseguire altre misure a causa della violazione dell’accordo
            da
            parte dell’utente senza assumersi la responsabilità per le conseguenze di tali azioni, per proteggere la
            responsabilità o la reputazione della piattaforma. In caso di sospensione, la Società si riserva inoltre il
            diritto di annullare l’accordo ai sensi dell’articolo 12.2 di seguito.
          </li>
          <li>
            11.3 Se l’utente ha violato il contratto o ha utilizzato la piattaforma in modo illegale o non autorizzato,
            l’utente è responsabile di risarcire la società per qualsiasi danno derivante da tali azioni (incluse, ma
            non
            limitate a, spese legali e altre richieste di risarcimento terzi). 11.4 La Società non è in grado di fornire
            informazioni sull’utente di cui è responsabile il professionista in conformità con i quadri normativi
            relativi
            alla protezione dei dati personali e che il professionista si oppone o non può pubblicare sulla Piattaforma.
          </li>
        </ul>

        <h2 class="do3-fw900">12. Durata del contratto e risoluzione del contratto</h2>
        <ul class="list-no-style indent30">
          <li>
            12.1 I presenti termini e condizioni e l’adesione agli stessi restano validi ed efficaci fino a nuovo avviso
            e
            dal momento in cui l’utente registra un account presso la Società. L’utente ha sempre il diritto di
            cancellare
            l’account, con effetto immediato. In caso di cancellazione, la Società deve rimuovere l’account annullato e
            tutte le informazioni memorizzate relative all’utente in breve tempo.

          </li>
          <p class="like-li">
            • Questi termini e condizioni rimarranno in vigore a tempo indeterminato fino a quando non saranno risolti
            da
            una parte mediante notifica all'altra parte, con 14 giorni di preavviso (eccetto in caso di inadempimento
            contrattuale).
          </p>
          <p class="like-li">
            • La società si riserva il diritto di aggiornare questi termini dando un preavviso di 14 giorni al Paziente,
            qualoraciò si riveli necessario per aggiornamenti alla soluzione tecnica della Società, alla Piattaforma, o
            per altre ragioni indifferibili che impongono la necessità di una modifica contrattuale. Le ragioni della
            modifica verranno comunicate al Paziente in maniera chiara ed esaustiva, fermo restando il diritto di
            recesso
            da parte del Paziente.
          </p>
          <p class="like-li">
            • Qualora una qualsiasi disposizione di questi termini e condizioni venga ritenuta nulla o inefficace, il
            resto delle disposizioni rimarrà completamente vincolante. La disposizione non valida sarà sostituita da una
            disposizione di legge pertinente.
          </p>
          <li>
            12.2 La Società ha il diritto di annullare il contratto, con effetto immediato, se l’utente viola l’accordo
            o
            se vi è un motivo per sospettare tale violazione, se l’utente non rettifica tale violazione entro trenta
            (30)
            giorni di notificato per iscritto. In caso di tale annullamento, l’utente verrà risolto, in base al quale il
            contratto viene automaticamente annullato. Le informazioni sull’utente che è stato memorizzato saranno
            rimosse
            prontamente.
          </li>
          <li>
            12.3 L’Utente riconosce che la Società può, a sua discrezione, disconnettere la password e l'account
            dell'Utente o interrompere l'utilizzo del servizio in ogni momento, previa comunicazione scritta via e-mail.
            La Società si riserva il diritto di dichiarare risolto il presente contratto, ai sensi dell'articolo 1456
            del
            Codice Civile, con comunicazione scritta da inviarsi all'Utente via e-mail, nel caso in cui l'Utente si sia
            reso inadempiente di una delle obbligazioni delle presenti Condizioni Generali.
          </li>
          <li>
            12.4 Un utente che è stato sospeso dall’account in conformità con questo articolo non ha il diritto di
            ri-registrare o registrare un nuovo account senza un permesso esplicito concesso dalla Società.
          </li>
        </ul>

        <h2 class="do3-fw900">13. Sviluppo del prodotto e modifiche nei Termini e condizioni</h2>
        <ul class="list-no-style indent30">
          <li>
            13.1 La Società si riserva il diritto di modificare l’ambito e le funzioni dell’Account e della Piattaforma.
            Lo sviluppo di prodotti e servizi potrebbe riguardare, anche se non esclusivamente, layout, contenuti o
            funzioni e potrebbe comportare modifiche all’accordo. Tali modifiche saranno comunicate tramite la
            Piattaforma
            e / o via e-mail. I termini e le condizioni in vigore saranno disponibili nella piattaforma.
          </li>
          <li>
            13.2 L’Utente ha il diritto di rescindere il contratto, con effetto immediato, nel caso in cui le modifiche
            ai
            termini e condizioni o del contratto risultino in uno svantaggio materiale per l’utente.
          </li>
        </ul>

        <h2 class="do3-fw900">14. Forza maggiore</h2>
        <ul class="list-no-style indent30">
          <li>
            14.1 La Società non offre alcun risarcimento per danni causati da scioperi, incendi, azioni dell’autorità
            governativa, controversie di lavoro, incidenti, errori o ritardi da parte di società controllate, chiusure o
            malfunzionamenti di sistemi di comunicazione pubblici o altre circostanze e conseguenze al di fuori del
            controllo della Società, che la Società non può ragionevolmente spiegare e che la Società non ha potuto né
            evitare né controllare. Nel caso in cui una circostanza che rientra in questo articolo rimanga dopo un
            periodo
            di un (1) mese, entrambe le parti hanno il diritto di risolvere il contratto, con effetto immediato.
          </li>
        </ul>

        <h2 class="do3-fw900">15. Notifiche</h2>
        <ul class="list-no-style indent30">
          <li>
            15.1 L’Utente deve indicare l’indirizzo e-mail e il numero di telefono sul quale desidera ricevere conferme,
            promemoria e altre notifiche da parte della Società. Nel caso in cui i dettagli del contatto cambino in
            qualsiasi momento durante il periodo di validità del contratto, l’Utente è tenuto a informare
            tempestivamente
            la Società. Cancellazioni o altre notifiche devono essere comunicate via e-mail.
          </li>
          <li>
            15.2 La notifica è considerata consegnata: a) se inviato per e-mail, messaggio di testo o fax; quando la
            consegna al destinatario è confermata; b) se inviato per posta raccomandata, due giorni dopo l’invio al
            servizio postale; o se inviato tramite corriere; c) alla consegna / accettazione da parte del destinatario.
          </li>
        </ul>

        <h2 class="do3-fw900">16. Dati personali </h2>
        <ul class="list-no-style indent30">
          <li>
            16.1 Le informazioni sul trattamento dei dati personali presenti nel sito della Società illustrano in modo
            dettagliato come la Società tratta i dati personali.
          </li>
        </ul>

        <h2 class="do3-fw900">17. Condizioni generali</h2>
        <ul class="list-no-style indent30">
          <li>
            17.1 L’Utente non può trasferire a terzi il contratto e/o i suoi obblighi o diritti.
          </li>
          <li>
            17.2 La Società si riserva il diritto di assumere sub-fornitori per adempiere ai propri diritti e obblighi
            previsti dal contratto.
          </li>
        </ul>

        <h2 class="do3-fw900">18. Mediazione introdotta per i servizi di intermediazione online</h2>
        <p>
          Dottorionline.com è soggetta all’applicazione del Regolamento UE 2019/1150 in materia di “equità e trasparenza
          per gli utenti commerciali dei servizi di intermediazione online”. Dottorionline.com ha predisposto un sistema
          interno di reclamo ex art. 11 Regolamento UE 2019/1150. In caso di
        </p>
        <p class="like-li">
          a) presunta inadempienza di dottorionline.com agli obblighi sanciti dallo stesso Regolamento UE 2019/1150,
        </p>
        <p class="like-li">
          b) problemi tecnologici collegati direttamente ai servizi forniti da dottorionline.com,
        </p>
        <p class="like-li">
          c) misure o comportamenti adottati da dottorionline.com che siano direttamente connessi alla fornitura dei
          servizi,
        </p>
        <br>
        <p>
          l’Utente-Professionista Sanitario può proporre reclamo ai sensi dell’art. 11 Regolamento UE 2019/1150,
          inviando
          apposita e-mail all’indirizzo assistenza-medici@dottorionline.com. Nel caso in cui tra Utente-Professionista
          Sanitario e dottorionline.com sia insorta una controversia stragiudiziale – non risolta nemmeno mediante
          eventuale reclamo al sistema interno – tale controversia può essere devoluta ai seguenti organismi di
          mediazione: a) Il Servizio di Conciliazione della Camera Arbitrale di Napoli; b) l’Organismo di Conciliazione
          dell’Ordine degli Avvocati di Napoli.
        </p>

        <h2 class="do3-fw900">19. Giurisdizione e Foro Competente</h2>
        <ul class="list-no-style indent30">
          <li>
            19.1 L’accordo è soggetto alla legge italiana. Eventuali controversie e procedimenti saranno soggetti alla
            giurisdizione italiana ed il foro competente è quello di Napoli.
          </li>
        </ul>

        <h2 class="do3-fw900">20. Uso della piattaforma fuori dal territorio nazionale</h2>
        <ul class="list-no-style indent30">
          <li>
            20.1 La Piattaforma e i Servizi sono progettati e destinati esclusivamente agli Utenti in Italia, a meno che
            non siano specificati esclusivamente dalla Società. La Società non garantisce che le informazioni e/o i
            servizi forniti tramite la Piattaforma siano applicabili, appropriati o disponibili al di fuori dell’Italia.
            L’accesso ai servizi della piattaforma da territori in cui qualsiasi contenuto e/o servizio può essere
            illegale è vietato.
          </li>
        </ul>

        <h2 class="do3-fw900">21. Requisiti tecnici</h2>
        <p>
          Per ricevere i servizi tramite la soluzione video, chat o audio della società, l’Utente deve assicurarsi di
          soddisfare i seguenti requisiti tecnici:
        </p>
        <ol>
          <li>
            disporre di un dispositivo con accesso a Internet con iOS, Android, Microsoft Windows o Mac OS;
          </li>
          <li>
            una velocità di connessione a banda larga di almeno 2 Mbps;
          </li>
          <li>
            microfono e telecamera installati e abilitati sul dispositivo;
          </li>
          <li>
            la versione corrente di Chrome, Safari o Firefox installata sul dispositivo con le applicazioni necessarie
            per
            connettersi alla Piattaforma.
          </li>
        </ol>
      </div>
    </div>
  </div>
</div>
