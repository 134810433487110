import { createAction, props } from '@ngrx/store';
import { AdditionalDetails, Products, ProductsDetail } from 'src/app/model/Products';

export const fetchProducts = createAction('[Products] Fetch Products');

export const fetchProductsSuccess = createAction(
  '[Products] Fetch Products Success',
  props<{ products: Products }>()
);

export const fetchProductsFailure = createAction(
  '[Products] Fetch Products Failure',
  props<{ error: any }>()
);

export const fetchProductDetailVersion = createAction(
  '[Products] Fetch Product Details Version',
  props<{ 
    versionId: string;
  }>()
);

export const fetchProductDetailVersionSuccess = createAction(
  '[Products] Fetch Product Details Version Success',
  props<{ additionalDetails: AdditionalDetails; }>()
);

export const fetchProductDetailVersionFailure = createAction(
  '[Products] Fetch Product Details Version Failure',
  props<{ error: any }>()
);

export const fetchProductDetail = createAction(
  '[Products] Fetch Product Details',
  props<{ 
    productId: string;
  }>()
);

// export const fetchProductDetailSuccess = createAction(
//   '[Products] Fetch Product Details Success',
//   props<{ productDetailsCombined: { productDetails: ProductsDetail; additionalDetails: AdditionalDetails } }>()
// );

export const fetchProductDetailSuccess = createAction(
  '[Products] Fetch Product Details Success',
  props<{ productDetails: ProductsDetail; }>()
);

export const fetchProductDetailFailure = createAction(
  '[Products] Fetch Product Details Failure',
  props<{ error: any }>()
);

export const productCheckout = createAction(
  '[Products] Stripe Checkout',
  props<{
    productId: string;
    successUrl: string;
    cancelUrl: string;
    couponCode?: string;
  }>()
);

export const productCheckoutSuccess = createAction(
  '[Products] Stripe Checkout Success',
  props<{
    checkOutUrl: any;
  }>()
);

export const productCheckoutFail = createAction(
  '[Product] Stripe Checkout Fail',
  props<{
    error: any;
    productId: string; 
  }>()
);
