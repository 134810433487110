import { Action, createReducer, on } from '@ngrx/store';
// import { CalendarRequestDatum } from 'src/app/model/CalendarRequest';
import { Meta, Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { TriageData } from 'src/app/model/Triage';
import * as RichiesteActions from './richieste.actions';
import { OrdersData } from 'src/app/model/Orders';

export interface State {
  allRichieste: RichiestaDatum[];
  allRichiesteMeta: Meta | null;
  requests: Richiesta | null;
  //   calendarRichieste: CalendarRequestDatum[];
  loading: boolean;
  selectedRichiesta: RichiestaDatum | null;
  selectedRichiestaDocuments: any[];
  error: boolean;
  errorMessage: any;
  triage: TriageData | null;
  roomUrl: string | null;
  cronologiaLoading: boolean;
  docsLoading: boolean;
  triageLoading: boolean;
  displayMyWellness: boolean;
  shareError: boolean;
  idDocs: string[];
  allOrders: OrdersData[] | null;
  toPayAlert: any;
  surveys: Richiesta | null;
}

const initialState: State = {
  allRichieste: [],
  allRichiesteMeta: null,
  requests: null,
  //   calendarRichieste: [],
  loading: false,
  selectedRichiesta: null,
  selectedRichiestaDocuments: [],
  error: false,
  errorMessage: null,
  triage: null,
  roomUrl: null,
  cronologiaLoading: false,
  docsLoading: false,
  triageLoading: false,
  displayMyWellness: false,
  shareError: false,
  idDocs: [],
  allOrders: null,
  toPayAlert: 0,
  surveys: null,
};

const _richiesteReducer = createReducer(
  initialState,

  on(RichiesteActions.fetchAllRichieste, (state) => ({
    ...state,
    allRichieste: [],
    loading: true,
    error: false,
    errorMessage: null,
    cronologiaLoading: true,
  })),

  on(RichiesteActions.fetchAllRichiesteSuccess, (state, action) => ({
    ...state,
    allRichieste: action.richieste.data,
    allRichiesteMeta: action.richieste.meta,
    loading: false,
    error: false,
    errorMessage: null,
    cronologiaLoading: false,
  })),

  on(RichiesteActions.fetchAllRichiesteFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchRequests, (state) => ({
    ...state,
    requests: null,
    loading: true,
    error: false,
    errorMessage: null,
    cronologiaLoading: true,
  })),

  on(RichiesteActions.fetchRequestsSuccess, (state, action) => ({
    ...state,
    requests: action.richieste,
    loading: false,
    error: false,
    errorMessage: null,
    cronologiaLoading: false,
  })),

  on(RichiesteActions.fetchRequestsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchRichiesteDetails, (state) => ({
    ...state,
    loading: true,
    displayMyWellness: false,
  })),

  on(RichiesteActions.fetchRichiestaDetailsSuccess, (state, action) => ({
    ...state,
    selectedRichiesta: action.richiesta,
    // selectedRichiestaDocuments: [],
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.deleteRichiestaSuccess, (state, action) => {
    return {
      ...state,
      error: false,
      errorMessage: null,
      allRichieste: state.allRichieste.filter((p) => p.id !== action.id),
    };
  }),

  on(RichiesteActions.deleteRichiestaFailed, (state, action) => {
    return {
      ...state,
      error: true,
      errorMessage: action.error,
    };
  }),

  on(RichiesteActions.fetchTriage, (state) => ({
    ...state,
    triageLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTriageSuccess, (state, action) => ({
    ...state,
    triage: action.triage,
    triageLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchTriageFailed, (state, action) => ({
    ...state,
    triageLoading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.postTriage, (state) => ({
    ...state,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postTriageSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.postTriageFailed, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.fetchRichiestaDocuments, (state) => ({
    ...state,
    selectedRichiestaDocuments: [],
    docsLoading: true,
    error: false,
    errorMessage: null,
    idDocs: [],
  })),

  on(RichiesteActions.fetchRichiestaDocumentsSuccess, (state, action) => ({
    ...state,
    selectedRichiestaDocuments: action.documents,
    docsLoading: false,
    error: false,
    errorMessage: null,
    idDocs: action.ids,
  })),

  on(RichiesteActions.fetchRichiestaDocumentsFailed, (state, action) => ({
    ...state,
    docsLoading: false,
    error: true,
    errorMessage: action.error,
    idDocs: [],
  })),

  on(RichiesteActions.fetchRichiestaMeetingSuccess, (state, action) => ({
    ...state,
    roomUrl: action.meeting,
    loading: false,
    error: false,
    errorMessage: null,
  })),

  on(RichiesteActions.fetchRichiestaMeetingFailed, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.myWellnessShare, (state) => ({
    ...state,
    loading: true,
  })),

  on(RichiesteActions.myWellnessShareSuccess, (state) => ({
    ...state,
    displayMyWellness: true,
    loading: false,
    shareError: false,
  })),

  on(RichiesteActions.myWellnessShareStatusSuccess, (state, action) => ({
    ...state,
    displayMyWellness: action.status === 'OK' ? true : false,
    loading: false,
    shareError: false,
  })),

  on(RichiesteActions.myWellnessShareStatusFailed, (state, action) => ({
    ...state,
    loading: false,
    shareError: true,
  })),

  on(RichiesteActions.fetchOrdersSuccess, (state, action) => ({
    ...state,
    allOrders: action.orders,
    toPayAlert: action.orders.filter((p: any) => p.paymentInfo.status === 'NEW')
      .length,
  })),

  on(RichiesteActions.fetchSurvey, (state) => ({
    ...state,
    loading: true,
  })),

  on(RichiesteActions.fetchSurveySuccess, (state, action) => ({
    ...state,
    loading: false,
    surveys: action.survey,
  })),

  on(RichiesteActions.fetchSurveyFailed, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
  })),

  on(RichiesteActions.resetRichieste, (state) => ({
    ...state,
    allRichieste: [],
    calendarRichieste: [],
    loading: false,
    selectedRichiesta: null,
    selectedRichiestaDocuments: [],
    error: false,
    errorMessage: null,
    triage: null,
    roomUrl: null,
    docsLoading: false,
    displayMyWellness: false,
    shareError: false,
    idDocs: [],
    allOrders: [],
    surveys: null,
  }))
);

export function richiesteReducer(state: State | undefined, action: Action) {
  return _richiesteReducer(state, action);
}
