import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { AuthModel } from 'src/app/auth/auth.model';
import * as AuthActions from '../../auth/store/auth.actions';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.scss'],
})
export class SidenavListComponent implements OnInit {
  @Output() sidenavClose = new EventEmitter();

  authSubscription!: Subscription;

  auth!: any | null;

  constructor(private store: Store<fromApp.AppState>, private router: Router) {}

  ngOnInit() {
    this.authSubscription = this.store
      .select('auth')
      .pipe(map((authState) => authState))
      .subscribe((authState) => {
        this.auth = authState.userData;
      });
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  onLogout() {
    this.router.navigate(['/loading']);
    this.store.dispatch(AuthActions.logout());
  }
}
