import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromApp from '../../../store/app.reducer';
import { resetPrenotazioni } from '../store/actions/prenotazione.action';
import { SlotDate } from 'src/app/model/Calendar';
import { MedicData } from 'src/app/model/MedicProfile';
import { Subscription } from 'rxjs';
import * as MedicProfileActions from '../../medic-profile/store/medic-profile.actions';
import { url } from 'inspector';

@Component({
  selector: 'app-successo-prenotazione',
  templateUrl: './successo-prenotazione.component.html',
  styleUrls: ['./successo-prenotazione.component.scss'],
})
export class SuccessoPrenotazioneComponent implements OnInit, OnDestroy {
  @Output() changeStep: EventEmitter<string> = new EventEmitter<string>();
  public selection: string = '';
  public bookedRequest: any | null;
  private doctorId: string = '';
  public doctorFromStore: any | null;
  private storeSubscription: Subscription;
  public formattedDate: string | null;
  public storedAppointment: any | null;
  public storedProductType: string | null;
  public showError: boolean = false;

  constructor(private router: Router, private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    // richiamo da localStorage i dati della prenotazione effettuata
    this.storedProductType = localStorage.getItem('productType');
    if (this.storedProductType && this.storedProductType === 'PATH') {
      this.storedProductType = 'Il percorso';
    }
    if (this.storedProductType && this.storedProductType === 'MEMBERSHIP') {
      this.storedProductType = "L'abbonamento";
    }

    // richiamo da localStorage i dati della prenotazione effettuata
    this.storedAppointment = localStorage.getItem('selectedAppointment');
    console.log(this.storedAppointment);
    if (this.storedAppointment) {
      this.bookedRequest = JSON.parse(this.storedAppointment);
    }

    // controllo se url non contiene sessionId e localStorage è vuoto per visualizzare pagina di errore
    const urlCheck = window.location.href;
    if (!urlCheck.includes('session_id=') && !this.storedAppointment) {
      this.showError = true;
    }

    if (this.bookedRequest) {
      // ottengo i dati del medico partendo dall'id
      this.doctorId = this.bookedRequest.doctorId;

      this.store.dispatch(
        MedicProfileActions.fetchMedicProfile({ medicId: this.doctorId })
      );

      this.storeSubscription = this.store
        .select('medicProfile')
        .subscribe((data) => {
          this.doctorFromStore = data.medic;
        });

      // formatto la data della prenotazione
      this.formatDate(this.bookedRequest.from);
    }

    // pulisco il localStorage
    localStorage.removeItem('selectedAppointment');
  }

  ngOnDestroy(): void {
    localStorage.removeItem('productType');
  }

  formatDate(date: String) {
    const [dateStr, timeStr] = date.split('T');
    const dateObj = new Date(dateStr);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const months = [
      'gennaio',
      'febbraio',
      'marzo',
      'aprile',
      'maggio',
      'giugno',
      'luglio',
      'agosto',
      'settembre',
      'ottobre',
      'novembre',
      'dicembre',
    ];
    const monthName = months[month - 1];
    const time = timeStr.split(':')[0] + ':' + timeStr.split(':')[1];

    return (this.formattedDate = `${day} ${monthName} ${year} alle ore ${time}`);
  }

  doChangeStep(direction: 'forward' | 'back') {
    this.changeStep.emit(direction);
  }

  goToApp() {
    // this.store.dispatch(resetPrenotazioni());
    // this.router.navigate(['/home'], {
    //   queryParams: { tab: 'richiesteTab' },
    // });

    window.location.href = 'https://app.drfeel.com/app/';
  }
}
