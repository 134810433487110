<div class="d-flex justify-content-end align-items-center topper-profile-user">
  <!-- <div class="avatar-container" [ngClass]="size">
    <img [matMenuTriggerFor]="menu" [src]="this.avatarUrl" alt="avatar" class="circle avatar" />
  </div> -->
  <div *ngIf="me" class="avatar-border">
    <div
      [ngClass]="{ 'do3-no-border': me.completionPercentage < 100 }"
      class="do3-border"
    ></div>
    <div
      [ngClass]="{ 'do3-no-border': me.completionPercentage < 25 }"
      class="do3-border"
    ></div>
    <div
      [ngClass]="{ 'do3-no-border': me.completionPercentage < 75 }"
      class="do3-border"
    ></div>
    <div
      [ngClass]="{ 'do3-no-border': me.completionPercentage < 50 }"
      class="do3-border"
    ></div>
    <ngx-avatars
      style="background-color: white; overflow: hidden"
      [matMenuTriggerFor]="menu"
      alt="avatar"
      [textSizeRatio]="3"
      bgColor="#2F2F2F "
      fgColor="white"
      class="circle my-avatar"
      [name]="this.fullName"
      size="77"
      [round]="true"
      [src]="avatarUrl"
    ></ngx-avatars>
  </div>

  <mat-menu #menu="matMenu" class="custom-men">
    <!-- <div mat-menu-item routerLink="/profilo">
      <div class="blue-left-border"></div>
      <mat-icon>person</mat-icon>
      Profilo
    </div>

    <div mat-menu-item routerLink="/cronologia">
      <div class="blue-left-border"></div>
      <mat-icon>history</mat-icon>
      Cronologia
    </div>

    <div mat-menu-item routerLink="/questionari">
      <div class="blue-left-border"></div>
      <mat-icon>checklist</mat-icon>
      Questionari
    </div>

    <div mat-menu-item routerLink="/pagamenti">
      <div class="blue-left-border"></div>
      <mat-icon>credit_card</mat-icon>
      Pagamenti
    </div> -->

    <!-- <div mat-menu-item routerLink="/percorsi">
      <div class="blue-left-border"></div>
      <mat-icon>emoji_events</mat-icon>
      Percorsi
    </div> -->

    <!-- <div mat-menu-item (click)="onClick()">
      <div class="blue-left-border"></div>
      <mat-icon>photo_camera</mat-icon>
      <input
        type="file"
        #fileUpload
        id="fileUpload"
        name="fileUpload"
        multiple="multiple"
        accept="image/*"
        style="display: none"
      />
      Carica foto
    </div> -->

    <div mat-menu-item (click)="onLogout()">
      <div class="blue-left-border"></div>
      <mat-icon>login</mat-icon>
      Logout
    </div>
  </mat-menu>
</div>
