<section class="documenti">
  <app-loading-spinner *ngIf="loading"></app-loading-spinner>


  <!-- NUOVO  -->
  <ng-container *ngIf="!loading">
    <div>
      <div class="d-flex justify-content-between mb-4">
        <h1 class="m-3">
          <strong>Documenti</strong>
        </h1>
        <div>
          <app-upload-doc [typeDocSelected]="typeDoc" [isRichiesta]="false">
            <div class="d-flex align-items-center">
              <div class="plus">+</div>
              <div class="text">CARICA</div>
            </div>
          </app-upload-doc>
        </div>
      </div>
      <div class="d-flex new-docs justify-content-between">
        <div class="">
          <div [ngClass]="{ active: typeDoc == 'diagnosis' }" class="single-item d-flex align-items-center"
            (click)="changeDocs('diagnosis')">
            <div class="svg">
              <svg id="Raggruppa_21184" data-name="Raggruppa 21184" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" width="26.88" height="30.856" viewBox="0 0 26.88 30.856">
                <defs>
                  <clipPath id="clip-path">
                    <rect id="Rettangolo_9265" data-name="Rettangolo 9265" width="26.88" height="30.856" fill="#2f2f2f"
                      stroke="#2f2f2f" stroke-width="0.5" />
                  </clipPath>
                </defs>
                <g id="Raggruppa_21120" data-name="Raggruppa 21120" clip-path="url(#clip-path)">
                  <path id="Tracciato_85347" data-name="Tracciato 85347"
                    d="M326.321,393.451h-1.315v-1.5a.481.481,0,0,0-.962,0v1.5h-1.571a.481.481,0,1,0,0,.962h1.571V395.8a.481.481,0,1,0,.962,0v-1.383h1.315a.481.481,0,1,0,0-.962"
                    transform="translate(-303.846 -369.404)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85348" data-name="Tracciato 85348"
                    d="M292.186,357.333a4.328,4.328,0,1,0,4.328,4.328,4.333,4.333,0,0,0-4.328-4.328m0,7.695a3.367,3.367,0,1,1,3.367-3.367,3.371,3.371,0,0,1-3.367,3.367"
                    transform="translate(-271.634 -337.194)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85349" data-name="Tracciato 85349"
                    d="M20.738,0H1.505A1.447,1.447,0,0,0,0,1.381V27.355a1.548,1.548,0,0,0,1.5,1.5H16a.481.481,0,0,0,0-.962H1.5a.589.589,0,0,1-.54-.539V1.381c0-.264.277-.419.543-.419H20.738a.389.389,0,0,1,.423.417V18.215a.481.481,0,1,0,.962,0V1.379A1.343,1.343,0,0,0,20.738,0"
                    fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85350" data-name="Tracciato 85350"
                    d="M151.424,59.733h-4.809a.481.481,0,1,0,0,.962h4.809a.481.481,0,0,0,0-.962"
                    transform="translate(-137.898 -56.366)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85351" data-name="Tracciato 85351"
                    d="M83.281,119.467H69.815a.481.481,0,1,0,0,.962H83.281a.481.481,0,0,0,0-.962"
                    transform="translate(-65.426 -112.734)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85352" data-name="Tracciato 85352"
                    d="M83.281,179.2H69.815a.481.481,0,1,0,0,.962H83.281a.481.481,0,1,0,0-.962"
                    transform="translate(-65.426 -169.1)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85353" data-name="Tracciato 85353"
                    d="M83.281,238.933H69.815a.481.481,0,1,0,0,.962H83.281a.481.481,0,1,0,0-.962"
                    transform="translate(-65.426 -225.467)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                  <path id="Tracciato_85354" data-name="Tracciato 85354"
                    d="M82.274,298.667H68.808c-.266,0-.541.155-.541.421V306.3a.59.59,0,0,0,.541.541H79.389a.481.481,0,1,0,0-.962H69.229v-6.252h12.5v1.383a.481.481,0,1,0,.962,0v-1.924a.384.384,0,0,0-.421-.421"
                    transform="translate(-64.42 -281.834)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                </g>
              </svg>
            </div>
            <div class="title">
              Referti
            </div>
          </div>
        </div>
        <div class="">
          <div [ngClass]="{ active: typeDoc == 'prescription' }" class="single-item d-flex align-items-center"
            (click)="changeDocs('prescription')">
            <div class="svg">
              <svg id="Raggruppa_21316" data-name="Raggruppa 21316" xmlns="http://www.w3.org/2000/svg" width="18.044"
                height="27" viewBox="0 0 18.044 27">
                <path id="Tracciato_85431" data-name="Tracciato 85431"
                  d="M129.778,7.1a3.73,3.73,0,0,1-3.423-2.734h1.254a1.373,1.373,0,0,0,1.476-1.234v-1.9A1.373,1.373,0,0,0,127.609,0H116.317a1.373,1.373,0,0,0-1.476,1.234v1.9a1.373,1.373,0,0,0,1.476,1.234h1.254A3.73,3.73,0,0,1,114.148,7.1a1.286,1.286,0,0,0-1.207,1.195V25.807A1.327,1.327,0,0,0,114.368,27h15.19a1.327,1.327,0,0,0,1.427-1.193V8.294A1.286,1.286,0,0,0,129.778,7.1Zm-.22,19.11h-15.19a.447.447,0,0,1-.481-.4V23.823h11.051a.4.4,0,1,0,0-.791H113.887V11.116l3.625,0a.4.4,0,1,0,0-.791l-3.625,0V8.294a.446.446,0,0,1,.411-.414c2.294-.308,4-1.755,4.227-3.515h3.048a.44.44,0,0,0,.473-.4.44.44,0,0,0-.473-.4h-5.256a.493.493,0,0,1-.53-.443V2.116h1.9a.44.44,0,0,0,.473-.4.44.44,0,0,0-.473-.4h-1.9V1.234a.493.493,0,0,1,.53-.443h11.292a.493.493,0,0,1,.53.443v1.02h-1.906a.44.44,0,0,0-.473.4.44.44,0,0,0,.473.4h1.906v.086a.493.493,0,0,1-.53.443h-3.82a.44.44,0,0,0-.473.4.44.44,0,0,0,.473.4H125.4c.225,1.761,1.932,3.208,4.226,3.515a.446.446,0,0,1,.411.414v2.031H119.712a.4.4,0,1,0,0,.791h10.327V23.032h-2.889a.4.4,0,1,0,0,.791h2.89v1.984A.447.447,0,0,1,129.558,26.209Z"
                  transform="translate(-112.941)" fill="#2f2f2f" />
                <path id="Tracciato_85432" data-name="Tracciato 85432"
                  d="M146.41,231.423a1.454,1.454,0,0,0,.6-1.113v-2.386a1.928,1.928,0,0,0-2-1.583,1.862,1.862,0,0,0-1.948,1.587v2.386C143.072,231.586,145.08,232.493,146.41,231.423Zm-2.056-4.05a1.037,1.037,0,0,1,1.67.554v.8h-1.982v-.8A.743.743,0,0,1,144.354,227.373Zm-.312,2.937v-.8h1.982v.8a.743.743,0,0,1-.312.55A1.031,1.031,0,0,1,144.042,230.31Zm6.438,1.244a2.409,2.409,0,0,0-2.8,0l-2.1,1.687c-1.108.906-.494,2.688,1.392,2.688a2.255,2.255,0,0,0,1.406-.447l2.1-1.687A1.444,1.444,0,0,0,150.481,231.554Zm-2.8,3.37a1.217,1.217,0,0,1-.706.212c-1.025,0-1.171-.948-.692-1.34l.7-.563,1.4,1.125Zm2.1-1.687-.7.563-1.4-1.125.7-.565a1.149,1.149,0,0,1,.705-.212C150.124,231.9,150.235,232.865,149.781,233.237Zm7.342-3.5c1.476-1.588,1.034-3.127-.1-3.8a3.023,3.023,0,0,0-3.246.175c-2.448-1.772-6,.778-3.347,3.628a10.889,10.889,0,0,0,3.123,1.888.6.6,0,0,0,.449,0A10.889,10.889,0,0,0,157.123,229.741Zm-3.347,1.088a9.169,9.169,0,0,1-2.558-1.561,2.028,2.028,0,0,1-.663-2.008,1.709,1.709,0,0,1,2.8-.344.563.563,0,0,0,.832,0,1.84,1.84,0,0,1,1.137-.565c1.392-.176,2.568,1.234,1,2.917A9.165,9.165,0,0,1,153.776,230.828Z"
                  transform="translate(-141.469 -213.633)" fill="#2f2f2f" />
              </svg>
            </div>
            <div class="title">
              Prescrizioni
            </div>
          </div>
        </div>
        <div class="">
          <div [ngClass]="{ active: typeDoc == 'analysis' }" class="single-item d-flex align-items-center"
            (click)="changeDocs('analysis')">
            <div class="svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="28.403" height="33.877" viewBox="0 0 28.403 33.877">
                <g id="Raggruppa_21602" data-name="Raggruppa 21602" transform="translate(-1.252 -0.954)">
                  <g id="Raggruppa_21614" data-name="Raggruppa 21614" transform="translate(0 0)">
                    <path id="Tracciato_85362" data-name="Tracciato 85362"
                      d="M110.294,78.243a4.77,4.77,0,0,1-4.764-4.764V57.705l-.49-.054a2.107,2.107,0,0,1,.235-4.2H110.2l-.459-.821a1.538,1.538,0,0,1-.2-.752,1.626,1.626,0,0,1,1.616-1.63,1.607,1.607,0,0,1,1.267.615l.389.494.439-.451a4.762,4.762,0,0,1,8.179,3.184v4.354l.182.164a1.891,1.891,0,0,1,.634,1.412,2.018,2.018,0,0,1-2,2.029h-1.157l-.043.5a6.308,6.308,0,0,1-2.01,4.1.034.034,0,0,1-.026.01.039.039,0,0,1-.028-.011.04.04,0,0,1-.012-.03.036.036,0,0,1,.013-.027,6.193,6.193,0,0,0,2.007-4.591.041.041,0,0,1,.039-.039h1.21a1.918,1.918,0,0,0,1.928-1.948,1.81,1.81,0,0,0-.794-1.5.044.044,0,0,1-.02-.035v-4.39a4.685,4.685,0,0,0-8.6-2.431c-.011.016-.017.017-.028.017-.026,0-.041-.011-.044-.032a1.528,1.528,0,0,0-1.524-1.319,1.506,1.506,0,0,0-1.123.5,1.579,1.579,0,0,0-.409,1.051,1.814,1.814,0,0,0,.661,1.3l.009.007a1.267,1.267,0,0,0,.8.264h3.961a2.107,2.107,0,0,1,.441,4.167l-.436.093V73.479a4.77,4.77,0,0,1-4.764,4.764m-4.685-4.764a4.685,4.685,0,0,0,9.371,0V57.664h-4.685a.039.039,0,0,1-.039-.039.039.039,0,0,1,.039-.039h4.759a2.028,2.028,0,0,0,0-4.055h-9.779a2.028,2.028,0,0,0,0,4.055h2.658a.039.039,0,0,1,0,.079h-2.323Z"
                      transform="translate(-93.191 -44.012)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="1.2" />
                    <path id="Tracciato_85363" data-name="Tracciato 85363"
                      d="M163.913,274.538a2.4,2.4,0,0,1-2.4-2.4v-7.388a.039.039,0,0,1,.039-.039h4.725a.039.039,0,0,1,.039.039v7.388a2.4,2.4,0,0,1-2.4,2.4m-2.323-2.4a2.323,2.323,0,1,0,4.646,0v-7.349H161.59Z"
                      transform="translate(-146.94 -242.31)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="1.2" />
                    <path id="Tracciato_85364" data-name="Tracciato 85364"
                      d="M2.8,85.591a2.8,2.8,0,1,1,2.8-2.8,2.8,2.8,0,0,1-2.8,2.8m0-5.512A2.717,2.717,0,1,0,5.512,82.8,2.72,2.72,0,0,0,2.8,80.079"
                      transform="translate(1.852 -72.146)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="1.2" />
                    <path id="Tracciato_85365" data-name="Tracciato 85365"
                      d="M83.583,7.166a3.583,3.583,0,1,1,3.583-3.583,3.587,3.587,0,0,1-3.583,3.583m0-7.087a3.5,3.5,0,1,0,3.5,3.5,3.508,3.508,0,0,0-3.5-3.5"
                      transform="translate(-71.848 1.554)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="1.2" />
                  </g>
                </g>
              </svg>
            </div>
            <div class="title">
              Esami
            </div>
          </div>
        </div>
        <div class="">
          <div [ngClass]="{ active: typeDoc == 'report' }" class="single-item d-flex align-items-center"
            (click)="changeDocs('report')">
            <div class="svg">
              <svg xmlns="http://www.w3.org/2000/svg" width="34.394" height="25.763" viewBox="0 0 34.394 25.763">
                <g id="Raggruppa_21615" data-name="Raggruppa 21615" transform="translate(-1.383 -1.384)">
                  <path id="Tracciato_85361" data-name="Tracciato 85361"
                    d="M3.87,25.264A3.875,3.875,0,0,1,0,21.394V3.871A3.875,3.875,0,0,1,3.87,0H30.024a3.875,3.875,0,0,1,3.87,3.87V21.394a3.875,3.875,0,0,1-3.87,3.87Zm19.391-9.6a1.615,1.615,0,0,0-1.172.506l-8.06,8.416H30.024a3.123,3.123,0,0,0,3.051-2.548l.06-.323L24.2,15.962l-.04-.024a1.6,1.6,0,0,0-.9-.276M.761,21.717l.059.318a3.125,3.125,0,0,0,3.047,2.55h9.1l5.283-5.521-6.087-3.792a1.616,1.616,0,0,0-1.856.109l-.036.03Zm22.5-6.835a2.349,2.349,0,0,1,1.357.429l.017.011,8.5,5.459V3.9a3.123,3.123,0,0,0-3.1-3.134H3.87A3.123,3.123,0,0,0,.767,3.9V20.778l9.075-6.01.014-.01a2.387,2.387,0,0,1,2.711-.138l6.227,3.863L21.54,15.62a2.377,2.377,0,0,1,1.717-.738"
                    transform="translate(1.633 1.633)" fill="#2f2f2f" stroke="#2f2f2f" stroke-width="0.5" />
                </g>
              </svg>
            </div>
            <div class="title">
              Immagini
            </div>
          </div>
        </div>
      </div>

      <div class="gray-border">
        <div [ngClass]="{ active: typeDoc == 'diagnosis' }" class="pink-row-1"></div>
        <div [ngClass]="{ active: typeDoc == 'prescription' }" class="pink-row-2"></div>
        <div [ngClass]="{ active: typeDoc == 'analysis' }" class="pink-row-3"></div>
        <div [ngClass]="{ active: typeDoc == 'report' }" class="pink-row-4"></div>
      </div>
    </div>

    <div class="d-md-none super-margin">
      <h1 class="do3-lightblue-text do3-fw800">Documenti</h1>
    </div>
    <div *ngIf="filtredDocs.length == 0" class="full-doc">
      <h1 class="text-center do3-fw600 text-blue pt-5">
        Al momento non ci sono documenti. <br />
        Clicca sul <span class="do3-lightblue-text do3-fw800">"Carica"</span> per
        caricare un documento medico.
      </h1>
    </div>
    <div *ngIf="filtredDocs.length != 0" class="full-doc">
      <div class="row do3-fw800 do3-blue-text header d-none d-md-flex">
        <div class="col-11">
          <div class="row">
            <div class="col-4">Nome</div>
            <!-- <div class="col-3">Dimensione</div> -->
            <div class="col-4">Caricato da</div>
            <div class="col-4">Data caricamento</div>
          </div>
        </div>
        <div class="col-1"></div>
      </div>
      <app-document-box *ngFor="let doc of filtredDocs" [doc]="doc"></app-document-box>

      <!-- Aggiungi i controlli di paginazione -->
      <div class="pagination mt-3">
        <button (click)="previousPage()" [disabled]="currentPage === 1">Precedente</button>
        <span>{{ currentPage }} / {{ getTotalPages() }}</span>
        <button (click)="nextPage()" [disabled]="currentPage === getTotalPages()">Successivo</button>
      </div>

    </div>
  </ng-container>
</section>