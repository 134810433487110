import { createAction, props } from '@ngrx/store';
import { OrdersData } from 'src/app/model/Orders';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';

export const fetchAllRichieste = createAction(
  '[Richieste] Fetch Richieste',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: any;
    page?: any;
  }>()
);

export const fetchAllRichiesteSuccess = createAction(
  '[Richieste] Fetch Richieste Success',
  props<{ richieste: Richiesta }>()
);

export const fetchAllRichiesteFailure = createAction(
  '[Richieste] Fetch Richieste Failure',
  props<{ error: any }>()
);

export const fetchRequests = createAction(
  '[Richieste] Fetch Requests',
  props<{
    searchRequest?: boolean;
    statusValue?: string;
    fromDate?: string;
    toDate?: string;
    page?: any;
  }>()
);

export const fetchRequestsSuccess = createAction(
  '[Richieste] Fetch Requests Success',
  props<{ richieste: Richiesta }>()
);

export const fetchRequestsFailure = createAction(
  '[Richieste] Fetch Requests Failure',
  props<{ error: any }>()
);

export const fetchRichiesteDetails = createAction(
  '[Richieste] Fetch Richieste Details',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaDetailsSuccess = createAction(
  '[Richieste] Fetch Richiesta Details Success',
  props<{ richiesta: RichiestaDatum }>()
);

export const fetchRichiestaDetailsFailure = createAction(
  '[Richieste] Fetch Richiesta Details Failure',
  props<{ error: any }>()
);

export const deleteRichiesta = createAction(
  '[Richieste] Delete Richiesta',
  props<{ id: string }>()
);

export const deleteRichiestaSuccess = createAction(
  '[Richieste] Delete Richiesta Success',
  props<{ id: string }>()
);

export const deleteRichiestaFailed = createAction(
  '[Richieste] Delete Richiesta Failed',
  props<{ error: any }>()
);

export const fetchTriage = createAction(
  '[Richieste] Fetch Triage',
  props<{ richiestaId: string }>()
);

export const fetchTriageSuccess = createAction(
  '[Richieste] Fetch Triage Success',
  props<{ triage: any }>()
);

export const fetchTriageFailed = createAction(
  '[Richieste] Fetch Triage Failed',
  props<{ error: any }>()
);

export const postTriage = createAction(
  '[Richieste] Post Triage',
  props<{ triage: any; richiestaId: string }>()
);

export const postTriageSuccess = createAction(
  '[Richieste] Post Triage Success',
  props<{ triage: any }>()
);

export const postTriageFailed = createAction(
  '[Richieste] Post Triage Failed',
  props<{ error: any }>()
);

export const fetchRichiestaDocuments = createAction(
  '[Richieste] Fetch Richiesta Documents',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaDocumentsSuccess = createAction(
  '[Richieste] Fetch Richiesta Documents Success',
  props<{ documents: any; ids: any }>()
);

export const fetchRichiestaDocumentsFailed = createAction(
  '[Richieste] Fetch Richiesta Documents Failed',
  props<{ error: any }>()
);

export const fetchRichiestaMeeting = createAction(
  '[Richieste] Fetch Richiesta Meeting',
  props<{ richiestaId: string }>()
);

export const fetchRichiestaMeetingSuccess = createAction(
  '[Richieste] Fetch Richiesta Meeting Success',
  props<{ meeting: any }>()
);

export const fetchRichiestaMeetingFailed = createAction(
  '[Richieste] Fetch Richiesta Meeting Failed',
  props<{ error: any }>()
);

export const fetchCalendarRequests = createAction(
  '[Richieste] Fetch Calendar Request',
  props<{
    fromDate?: string;
    toDate?: string;
    status?: string;
    page?: number;
    pageSize?: number;
  }>()
);

export const fetchCalendarRequestsSuccess = createAction(
  '[Richieste] Fetch Calendar Request Success',
  props<{ requests: any }>()
);

export const fetchCalendarRequestsFailed = createAction(
  '[Richieste] Fetch Calendar Request Failed',
  props<{ error: any }>()
);

export const myWellnessShareStatus = createAction(
  '[Richieste] My Wellness Status',
  props<{ doctorId: string }>()
);

export const myWellnessShareStatusSuccess = createAction(
  '[Richieste] My Wellness Status Success',
  props<{ status: any }>()
);

export const myWellnessShareStatusFailed = createAction(
  '[Richieste] My Wellness Status Failed',
  props<{ error: any }>()
);

export const myWellnessShare = createAction(
  '[Richieste] Share Movergy',
  props<{ doctorId: string }>()
);

export const myWellnessShareSuccess = createAction(
  '[Richieste] Share Movergy Success',
  props<{ movergy: any }>()
);

export const myWellnessShareFailed = createAction(
  '[Richieste] Share Movergy Failed',
  props<{ error: any }>()
);

export const linkDocToRequest = createAction(
  '[Richieste] Link Doc To Request',
  props<{ requestId: string; documentId: string }>()
);

export const linkDocToRequestSuccess = createAction(
  '[Richieste] Link Doc To Request Success',
  props<{ requestId: string }>()
);

export const linkDocToRequestFailed = createAction(
  '[Richieste] Link Doc To Request Failed',
  props<{ error: any }>()
);

export const fetchOrders = createAction('[Richieste] Fetch Orders');

export const fetchOrdersSuccess = createAction(
  '[Richieste] Fetch Orders Success',
  props<{ orders: OrdersData[] }>()
);

export const fetchOrdersFailed = createAction(
  '[Richieste] Fetch Orders Failed',
  props<{ error: any }>()
);

export const orderCheckout = createAction(
  '[Order] Stripe Checkout',
  props<{
    orderId: string;
    successUrl: string;
    cancelUrl: string;
    couponCode?: string;
  }>()
);

export const orderCheckoutSuccess = createAction(
  '[Order] Stripe Checkout Success',
  props<{
    checkOutUrl: any;
  }>()
);

export const orderCheckoutFail = createAction(
  '[Order] Stripe Checkout Fail',
  props<{
    error: any;
  }>()
);

export const fetchSurvey = createAction('[Richieste] Fetch Survey');

export const fetchSurveySuccess = createAction(
  '[Richieste] Fetch Survey Success',
  props<{ survey: any }>()
);

export const fetchSurveyFailed = createAction(
  '[Richieste] Fetch Survey Failed',
  props<{ error: any }>()
);

export const resetRichieste = createAction('[Richieste] Reset Richieste');
