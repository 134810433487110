<div class="container main-container do3-gray-text">
  <div class="cookie-policy row">
    <div class="offset-lg-1 col-lg-10">

      <div class="text-card">
        <h2 class="do3-fw900">Cookie policy</h2>
        <p>
          Questa pagina descrive quali informazioni vengono da noi raccolte attraverso i cookie, come le usiamo e perché
          a
          volte abbiamo bisogno di memorizzare e conservare questi cookie. Spieghiamo inoltre in questa pagina come
          impedire
          la memorizzazione di questi cookie, anche se ciò potrebbe ridurre o “interrompere” alcuni elementi e
          funzionalità
          del sito web. Il presente sito web utilizza cookie tecnici per garantire il corretto funzionamento delle
          procedure
          e
          migliorare l’esperienza di uso delle applicazioni online. Il presente documento fornisce informazioni sull’uso
          dei
          cookie e di tecnologie similari, su come sono utilizzati dal sito e su come gestirli.

        </p>
        <h2 class="do3-fw900">Definizioni</h2>
        <p>
          I cookie sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, ove vengono
          memorizzati per essere poi ritrasmessi agli stessi siti alla visita successiva. I cookie delle c.d. “terze
          parti”
          vengono, invece, impostati da un sito web diverso da quello che l’utente sta visitando. Questo perché su ogni
          sito
          possono essere presenti elementi (immagini, mappe, suoni, specifici link a pagine web di altri domini, ecc.)
          che
          risiedono su server diversi da quello del sito visitato.
        </p>

        <h2 class="do3-fw900">Tipologie di cookie </h2>
        <p>
          In base alle caratteristiche e all’utilizzo dei cookie si possono distinguere diverse categorie:

        </p>
        <ul>
          <li>
            Cookie tecnici. I cookie tecnici sono quelli utilizzati al solo fine di “effettuare la trasmissione di una
            comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al fornitore
            di
            un
            servizio della società dell’informazione esplicitamente richiesto dall’abbonato o dall’utente a erogare tale
            servizio” (cfr. art. 122, comma 1, del Codice).Essi non vengono utilizzati per scopi ulteriori e sono
            normalmente
            installati direttamente dal titolare o gestore del sito web. Possono essere suddivisi in cookie di
            navigazione o
            di sessione, che garantiscono la normale navigazione e fruizione del sito web; cookie analytics, assimilati
            ai
            cookie tecnici laddove utilizzati direttamente dal gestore del sito per raccogliere informazioni, in forma
            aggregata, sul numero degli utenti e su come questi visitano il sito stesso; cookie di funzionalità, che
            permettono all’utente la navigazione in funzione di una serie di criteri selezionati al fine di migliorare
            il
            servizio reso allo stesso. Per l’installazione di tali cookie non è richiesto il preventivo consenso degli
            utenti,
            mentre resta fermo l’obbligo di dare l’informativa ai sensi dell’art. 13 del Codice, che il gestore del
            sito,
            qualora utilizzi soltanto tali dispositivi, potrà fornire con le modalità che ritiene più idonee.

          </li>
          <li>
            Cookie di profilazione. I cookie di profilazione sono volti a creare profili relativi all’utente e vengono
            utilizzati al fine di inviare messaggi pubblicitari in linea con le preferenze manifestate dallo stesso
            nell’ambito della navigazione in rete. In ragione della particolare invasività che tali dispositivi possono
            avere
            nell’ambito della sfera privata degli utenti, la normativa europea e italiana prevede che l’utente debba
            essere
            adeguatamente informato sull’uso degli stessi ed esprimere così il proprio valido consenso. Ad essi si
            riferisce
            l’art. 122 del Codice laddove prevede che “l’archiviazione delle informazioni nell’apparecchio terminale di
            un
            contraente o di un utente o l’accesso a informazioni già archiviate sono consentiti unicamente a condizione
            che
            il
            contraente o l’utente abbia espresso il proprio consenso dopo essere stato informato con le modalità
            semplificate
            di cui all’articolo 13, comma 3” (art. 122, comma 1, del Codice). Il presente sito non utilizza cookie di
            profilazione.

          </li>
        </ul>
        <h2 class="do3-fw900">I cookie possono, ancora, essere classificati come:</h2>
        <ul>
          <li>
            Cookie di prima parte (first-part cookie) ossia cookie generati e gestiti direttamente dal soggetto gestore
            del
            sito web sul quale l’utente sta navigando.

          </li>

          <li>
            Cookie di terza parte (third-part cookie), i quali sono generati e gestiti da soggetti diversi dal gestore
            del
            sito web sul quale l’utente sta navigando (in forza, di regola, di un contratto tra il titolare del sito web
            e
            la
            terza parte).

          </li>
          <li>
            Cookie di “terze parti”

          </li>
        </ul>
        <p>
          Visitando il presente sito web si potrebbero ricevere cookie da siti gestiti da altre organizzazioni (“terze
          parti”). Un esempio è rappresentato dalla presenza dei “social plugin” per Facebook, Twitter, Google+ o
          LinkedIn,
          oppure sistemi di visualizzazione di contenuti multimediali embedded (integrati) come ad esempio Youtube,
          Flikr.
          Si
          tratta di parti generate direttamente dai suddetti siti ed integrati nella pagina web del sito ospitante
          visitato.
          La presenza di questi plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze
          parti. La
          gestione delle informazioni raccolte da “terze parti” è disciplinata dalle relative informative cui si prega
          di
          fare
          riferimento. Per garantire una maggiore trasparenza e comodità, si riportano qui di seguito gli indirizzi web
          delle
          diverse informative e delle modalità per la gestione dei cookie. <br>
          Facebook informativa: https://www.facebook.com/help/cookies/ <br>
          Facebook (configurazione): accedere al proprio account. Sezione privacy. <br>
          Twitter informative: https://support.twitter.com/articles/20170514 <br>
          Twitter (configurazione): https://twitter.com/settings/security <br>
          Linkedin informativa: https://www.linkedin.com/legal/cookie-policy <br>
          Linkedin (configurazione): https://www.linkedin.com/settings/ <br>
          Youtube\Google+ informativa: http://www.google.it/intl/it/policies/technologies/cookies/ <br>
          Youtube\Google+ (configurazione): http://www.google.it/intl/it/policies/technologies/managing/ <br>
          Pinterest informativa\configurazione https://about.pinterest.com/it/privacy-policy <br>
          Flikr\Yahoo informativa http://info.yahoo.com/privacy/it/yahoo/cookies/details.html <br>
          Flikr\Yahoo (configurazione)http://info.yahoo.com/privacy/it/yahoo/opt_out/targeting/details.html <br>
        </p>
        <h2 class="do3-fw900">Cookie analytics </h2>
        <p>
          WebTrends
          Al solo fine di monitorare e migliorare le prestazioni del sito ci si avvale di un prodotto di mercato di
          analisi
          statistica per la rilevazione degli accessi al sito. Esso può ricorrere all’utilizzo di cookies, permanenti e
          non,
          allo scopo di raccogliere informazioni statistiche e sui “visitatori unici” del sito. I cookies, definiti come
          “Unique Visitor Cookies”, contengono un codice alfanumerico che identifica i computer di navigazione, senza
          tuttavia
          alcuna raccolta di dati personali.
        </p>
        <h2 class="do3-fw900">Google Analytics</h2>
        <p>
          Il sito include anche componenti trasmesse da Google Analytics, un servizio di analisi del traffico web
          fornito da
          Google, Inc. (“Google”). Tali cookie sono usati al solo fine di monitorare e migliorare le prestazioni del
          sito.
          Per
          ulteriori informazioni, si rinvia al link di seguito indicato:
          https://www.google.it/policies/privacy/partners/
        </p>
        <p>
          L’utente può disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio browser la
          componente di opt-out fornito da Google. Per disabilitare l’azione di Google Analytics, si rinvia al link di
          seguito
          indicato: https://tools.google.com/dlpage/gaoptout
        </p>

        <h2 class="do3-fw900">Cookie del browser (cookie tecnici) </h2>
        <p>
          Dottorionline utilizza i cookie che sono strettamente necessari per il funzionamento del Sito, per la
          prestazione
          dei servizi richiesti dall’utente, per la protezione del Sito contro gli attacchi informatici e per la
          conservazione
          del suo consenso all’installazione dei Cookie. Tali Cookie sono essenziali per la corretta navigazione sul
          Sito.
          La
          loro cancellazione può rendere difficile o impossibile navigare sul Sito.
        </p>
        <h2 class="do3-fw900">Durata dei cookie </h2>
        <p>
          Alcuni cookie (cookie di sessione) restano attivi solo fino alla chiusura del browser o all’esecuzione
          dell’eventuale comando di logout. Altri cookie “sopravvivono” alla chiusura del browser e sono disponibili
          anche
          in
          successive visite dell’utente. Questi cookie sono detti persistenti e la loro durata è fissata dal server al
          momento
          della loro creazione. In alcuni casi è fissata una scadenza, in altri casi la durata è illimitata.
        </p>
        <h2 class="do3-fw900">Gestione dei cookie </h2>
        <p>
          L’utente può autorizzare, limitare o bloccare i cookie utilizzando le impostazioni del proprio browser.
          Attenzione:
          con la disabilitazione totale o parziale dei cookie tecnici potrebbe compromettere l’utilizzo ottimale del
          sito.
          La
          disabilitazione dei cookie “terze parti” non pregiudica in alcun modo la navigabilità. L’impostazione può
          essere
          definita in modo specifico per i diversi siti e applicazioni web. Inoltre, i browser consentono di definire
          impostazioni diverse per i cookie “proprietari” e per quelli di “terze parti”. A titolo di esempio, in
          Firefox,
          attraverso il menu Strumenti->Opzioni->Privacy, è possibile accedere ad un pannello di controllo dove è
          possibile
          definire se accettare o meno i diversi tipi di cookie e procedere alla loro rimozione. In internet è
          facilmente
          reperibile la documentazione su come impostare le regole di gestione dei cookies per il proprio browser, a
          titolo
          di
          esempio si riportano alcuni indirizzi relativi ai principali browser: <br>
          Chrome: https://support.google.com/chrome/answer/95647?hl=it <br>
          Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie <br>
          Internet Explorer: http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9
          <br>
          In questa sezione trovi le informazioni per disattivare i cookie sul tuo browser. Se il tuo browser non si
          trova
          nell’elenco sotto riportato, ti preghiamo di consultare le istruzioni riportate sul tuo browser in merito alla
          gestione dei cookie.
        </p>
        <p class="do3-fw900">Internet Explorer versione 6 o superiore</p>
        <ul>
          <li>
            Seleziona “Strumenti” nella barra del tuo browser
          </li>
          <li>
            Seleziona “Opzioni Internet”
          </li>
          <li>
            Seleziona la voce “Privacy” e poi clicca su “Avanzate”
          </li>
          <li>
            Seleziona “Sostituisci gestione automatica cookie”
          </li>
          <li>
            Disattiva i “Cookie dei siti Web visualizzati” selezionando la voce “Blocca”
          </li>
          <li>
            Disattiva i “Cookie di terze parti” selezionando la voce “Blocca”
          </li>
          <li>
            Disattiva i “Cookie di sessione” deselezionando la voce “Accetta sempre i cookie della sessione”
          </li>
          <li>
            Clicca su “OK”
          </li>
        </ul>
        <p class="do3-fw900">Firefox versione 9 o superiore </p>
        <ul>
          <li>
            Seleziona “Strumenti” nella barra del tuo browser
          </li>
          <li>
            Seleziona “Opzioni”
          </li>
          <li>
            Selezione la voce “Privacy”
          </li>
          <li>
            Nell’area “Cronologia” scegli dal menù a tendina l’opzione “utilizza impostazioni personalizzate”
          </li>
          <li>
            Disattiva i cookies deselezionando la voce “Accetta i cookie dai siti”

          </li>
          <li>
            Clicca su “OK”
          </li>
        </ul>
        <p class="do3-fw900">Google Chrome versione 24 o superiore </p>
        <ul>
          <li>
            Seleziona “Menù Chrome” nella barra del tuo browser
          </li>
          <li>
            Selezione “impostazioni”
          </li>
          <li>
            Seleziona “Mostra impostazione avanzate”
          </li>
          <li>
            Nella sezione “Privacy” clicca su “Impostazione contenuti”
          </li>
          <li>
            Disattiva tutti i cookies selezionando “Impedisci ai siti di impostare dati” e “Blocca cookie di terze parti
            e
            dati dei siti”
          </li>
          <li>
            Clicca su “OK”
          </li>
        </ul>

      </div>
    </div>
  </div>
</div>
