<!-- loggato -->
<mat-nav-list *ngIf="auth">
  <!-- <a mat-list-item routerLink="/home" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>assignment_turned_in</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Home</span>
  </a> -->
  <a mat-list-item routerLink="/assistenza" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>contact_support</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Centro assistenza</span>
  </a>
  <a mat-list-item routerLink="" (click)="onLogout()">
    <mat-icon>login</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Logout</span>
  </a>
</mat-nav-list>
<!-- non loggato  -->
<mat-nav-list *ngIf="!auth">
  <a mat-list-item routerLink="/login" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>login</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Accedi </span>

  </a>
  <a mat-list-item routerLink="/registrati" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>account_circle</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Registrati </span>

  </a>

  <a mat-list-item routerLink="/assistenza" routerLinkActive="activeLink" (click)="onSidenavClose()">
    <mat-icon>contact_support</mat-icon><span class="nav-caption do3-blue-text do3-fw600">Centro assistenza</span>
  </a>
</mat-nav-list>
