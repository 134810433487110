<!-- <div class="container mt-5">
</div> -->

<ng-container *ngIf="productDetails">
  <div class="container py-5">
    <!-- <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info> -->

    <app-loading-spinner *ngIf="loading"></app-loading-spinner>

    <!-- bottone torna indietro 
    <div class="d-inline-flex align-items-center">
      <button class="do3-back-btn p-0" (click)="goBack()">
        <svg
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.17086 7.08217C0.943049 7.31293 0.943049 7.68707 1.17086 7.91784L7.00419 13.8269C7.23199 14.0577 7.60134 14.0577 7.82915 13.8269C8.05695 13.5962 8.05695 13.222 7.82915 12.9913L2.40829 7.5L7.82914 2.00875C8.05695 1.77798 8.05695 1.40384 7.82914 1.17307C7.60134 0.942309 7.23199 0.942309 7.00419 1.17307L1.17086 7.08217Z"
            fill="black"
            stroke="black"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <span class="d-none d-md-block ms-3">Torna indietro</span>
      </button>
    </div> -->

    <!-- PERCORSO -->
    <ng-container *ngIf="productDetails.type !== 'MEMBERSHIP'; else memberTx">
      <section
        id="percorso"
        class="mx-auto d-flex flex-column align-items-center justify-content-between"
      >
        <!-- immagine percorso -->
        <img
          *ngIf="
            productDetails.images[0].url && productDetails.type !== 'MEMBERSHIP'
          "
          id="percorso-image"
          src="{{ productDetails.images[0].url }}"
          alt="{{ productDetails.name }}"
          class="w-100"
        />
        <img
          *ngIf="
            !productDetails.images[0].url ||
            productDetails.type === 'MEMBERSHIP'
          "
          id="percorso-image"
          src="../../../../../assets/images/percorsi/placeholder.png"
          alt="{{ productDetails.name }}"
          class="w-100"
        />

        <!-- nome e descrizione percorso -->
        <div id="percorso-description">
          <h1 class="title">
            {{ productDetails.code === "BUNDLE3" ? "Regalati il tuo percorso di salute e benessere" : productDetails.name }}
          </h1>
          <p class="text">
            {{ productDetails.code === "BUNDLE3" ? "Acquista 3 videoconsulti con uno Specialista a tua scelta, poi contatta la Health Manager in chat per prenotarli quando ti è più comodo." : productDetails.description }}
          </p>
        </div>

        <!-- Percorso già acquistato -->
        <ng-container *ngIf="productDetails.purchaseDate">
          <div id="purchased-product">
            Questo percorso è già stato acquistato il
            {{ productDetails.purchaseDate | date : " dd MM yyyy" }}
          </div>
        </ng-container>

        <!-- prezzo percorso -->
        <div id="price" class="w-100">
          <!-- Percorso non versionato non attivo  -->
          <ng-container
            *ngIf="!productDetails.versioned && !productDetails.purchasable"
          >
            <div class="price-detail">Questo percorso non è attivo</div>
          </ng-container>

          <!-- Percorso non versionato attivo -->
          <ng-container
            *ngIf="!productDetails.versioned && productDetails.purchasable"
          >
            <div class="w-100 d-flex flex-column align-items-center gap-4">
              <p class="price-detail">
                Prezzo
                {{
                  productDetails.type === "MEMBERSHIP"
                    ? "Abbonamento"
                    : "Percorso"
                }}: <br class="d-md-none" /><span
                  >€ {{ productDetails.price | number : "1.2-2" }}</span
                >
                <br class="d-md-none">
                <span class="ms-2 text-decoration-line-through fw-normal fs-4">{{ productDetails.code === "BUNDLE3" ? "anziché 180€" : null}}</span>
              </p>
              <button
                *ngIf="!productDetails.versioned"
                class="do3-secondary-btn lightBlue percorso-btn"
                (click)="payOrder(productDetails.id)"
              >
                Acquista
              </button>
              <!-- <button
              class="do3-secondary-btn lightBlue mb-3 mb-md-0 me-3 percorso-btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModalCenteredScrollable"
              (click)="getProductDetails(productDetails.id)"
              *ngIf="productDetails.type !== 'MEMBERSHIP'"
            >
              Visualizza Dettagli
            </button> -->
            </div>
          </ng-container>

          <!-- Percorso versionato attivo -->
          <ng-container
            *ngIf="productDetails.versioned && productDetails.purchasable"
          >
            <div
              class="d-flex justify-content-center gap-5 flex-column flex-md-row align-items-center text-center"
            >
              <div
                class="product-column"
                *ngFor="let detail of productDetails?.versions"
              >
                <!-- <img
                [src]="detail.images[0].url"
                alt="{{ detail.name }}"
                width="250"
                *ngIf="productDetails.type !== 'MEMBERSHIP'"
              /> -->
                <p *ngIf="productDetails.type === 'MEMBERSHIP'">
                  {{ detail.description }}
                </p>
                <p
                  *ngIf="productDetails.type !== 'MEMBERSHIP'"
                  class="text-uppercase"
                >
                  {{ detail.name }}
                </p>
                <div class="w-100 d-flex flex-column align-items-center gap-4">
                  <p class="price-detail">
                    Prezzo
                    {{
                      detail.type === "MEMBERSHIP" ? "Abbonamento" : "Percorso"
                    }}: <br class="d-md-none" /><span
                      >€ {{ detail.price | number : "1.2-2" }}</span
                    >
                  </p>
                  <button
                    *ngIf="!detail.versioned"
                    class="do3-secondary-btn lightBlue percorso-btn"
                    (click)="payOrder(detail.id)"
                  >
                    Acquista
                  </button>
                </div>
                <!-- <h2 class="text-uppercase my-3">
                {{ detail.name }}
                {{ detail.price | number : "1.2-2" }}
              </h2>
              <button
                class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                (click)="payOrder(detail.id)"
              >
                Acquista
              </button>

              <button
                class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalCenteredScrollable"
                (click)="getProductDetails(detail.id)"
                *ngIf="productDetails.type !== 'MEMBERSHIP'"
              >
                Visualizza Dettagli
              </button> -->
              </div>
            </div>
          </ng-container>
        </div>
      </section>
    </ng-container>

    <!-- MEMBERSHIP -->
    <ng-template #memberTx>

      <div class="d-flex flex-column justify-content-center align-items-center text-center" id="offer_expired" *ngIf="showSection">
        <h2 class="fs-1 fw-bold lh-base">L'offerta è Scaduta</h2>
        <p>Scopri la nuova offerta Dr.Feel disponibile.</p>

        <button class="do3-secondary-btn lightBlue percorso-btn text-uppercase" (click)="goToPayWall()"> Acquista</button>
      </div>

      <div class="d-flex flex-column justify-content-center align-items-center" *ngIf="productId">
        <app-loading-spinner></app-loading-spinner>
        <h1>A breve verrai reindirizzato alla pagina di pagamento...</h1>
      </div>
      
      <section
        *ngIf="!productId"
        id="membership"
        class="d-flex mx-auto align-items-center justify-content-between"
      >           
        <div class="tabs-container">
          <div class="nav nav-tabs border-0 justify-content-center shadow" id="nav-tab" role="tablist">
            <button
              class="nav-link py-3 px-2 fs-6"
              [ngClass]="{ active: activeTab === 'individuale' }"
              (click)="setActiveTab('individuale')"
            >
              Individuale
            </button>
            <button
              class="nav-link py-3 px-2 fs-6"
              [ngClass]="{ active: activeTab === 'famiglia' }"
              (click)="setActiveTab('famiglia')"
            >
              Family
            </button>
          </div>
          <div class="tab-content mt-5" id="nav-tabContent">
            <div
              class="tab-pane fade"
              [ngClass]="{ 'show active': activeTab === 'individuale' }"
              id="individuale"
            >
              <!-- Contenuto per Individuale -->
              <div class="card-container">
                <div class="card shadow" *ngFor="let detail of productDetails?.versions">
                  <div class="card-head position-absolute top-0 start-0">
                    <h3 class="m-0 fw-bold">
                      {{ detail.name }}
                    </h3>
                  </div>
                  <div class="card-body text-wrap mt-5">
                    <h2 class="card-title fw-bold mb-1 mt-4 text-center mb-4">{{ detail.price | number : "1.0-0" }}€<span class="fs-6">/{{detail.name === "Mensile" ? "mese" : "anno"}}</span></h2>
                    <!-- <h5 class="card-subtitle my-2 text-center" [innerHTML]="detail.description ">{{ detail.description }}</h5> -->
                    <h5 class="card-subtitle my-2 text-center"></h5>
                    <p class="card-text fs-6">Il tuo servizio sanitario digitale senza attese.</p>      
                    <ul class="list-group text-start px-3 fw-normal">
                      <li><span class="colorDrfeel">Assistenza sanitaria immediata via chat</span> con Health Manager personale</li>
                      <li><span class="colorDrfeel">Videoconsulti illimitati</span> con il Medico personale</li>
                      <li><span class="colorDrfeel">Prescrizioni</span> in app</li>
                      <li><span class="colorDrfeel">Prenotazione</span> di esami e visite</li>
                      <li><span class="colorDrfeel">Piani digitali di fitness</span>, yoga e meditazione in partnership con Technogym</li>
                      <li><span class="colorDrfeel">Valutazione mensile</span> del tuo stato di salute</li>
                      <li>Altri servizi esclusivi per la salute</li>
                    </ul>
                    <p class="text-gray fs-7 text-center mt-3">In App, 8:00-23:00, anche sabato e domenica.</p>
                    <div
                      class="product-column"
                      
                    >
                      <!-- <img
                  [src]="detail.images[0].url"
                  alt="{{ detail.name }}"
                  width="250"
                  *ngIf="productDetails.type !== 'MEMBERSHIP'"
                
                /> -->

                      <button
                        class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                        (click)="payOrder(detail.id)"
                      >
                        Attiva ora
                      </button>

                      <p class="small text-center fw-normal">{{detail.name === "Mensile" ? "Pagamento mensile, disdici quando vuoi" : "Pagamento su base annuale"}}</p>
  
                      <!-- <button
                        class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenteredScrollable"
                        (click)="getProductDetails(detail.id)"
                      >
                        Visualizza Dettagli
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
             
              <div
                  class="d-flex justify-content-center gap-5 flex-column flex-md-row align-items-center text-center"
                >
                  
                </div>
            </div>
            <div
              class="tab-pane fade"
              [ngClass]="{ 'show active': activeTab === 'famiglia' }"
              id="famiglia"
            >
              <!-- Contenuto per Famiglia -->
              <div class="card-container">
                <div class="card shadow" *ngFor="let products_family_detail of products_family?.versions">
                  <div class="card-head position-absolute top-0 start-0">
                    <h3 class="m-0 fw-bold">
                      {{ products_family_detail.name }}
                    </h3>
                  </div>
                  <div class="card-body text-wrap mt-5">
                    <h2 class="card-title fw-bold mb-1 mt-4 text-center mb-4">{{ products_family_detail.price | number : "1.0-0" }}€<span class="fs-6">/{{products_family_detail.name === "Mensile" ? "mese" : "anno"}}</span></h2>
                    <!-- <h5 class="card-subtitle my-2 text-center" [innerHTML]="products_family_detail.description ">{{ products_family_detail.description }}</h5> -->
                    <h5 class="card-subtitle my-2 text-center"></h5>
                    <p class="card-text fs-6">Il tuo servizio sanitario digitale senza attese per tutta la famiglia.</p>
                    <ul class="list-group text-start px-3 fw-normal">
                      <li><span class="colorDrfeel">Assistenza sanitaria immediata via chat</span> con Health Manager personale</li>
                      <li><span class="colorDrfeel">Videoconsulti illimitati</span> con Medico e Pediatra</li>
                      <li><span class="colorDrfeel">Prescrizioni</span> in app</li>
                      <li><span class="colorDrfeel">Prenotazione</span> di esami e visite</li>
                      <li><span class="colorDrfeel">Piani digitali di fitness</span>, yoga e meditazione in partnership con Technogym</li>
                      <li><span class="colorDrfeel">Valutazione mensile</span> del tuo stato di salute</li>
                      <li>Altri servizi esclusivi per la salute</li>
                    </ul>
                    <p class="fw-normal pt-2">Accesso fino a 4 persone del nucleo famigliare.</p>
                    <p class="text-gray fs-7 text-center">In App, 8:00-23:00, anche sabato e domenica.</p>
                    <div
                      class="product-column"
                      
                    >
                      <!-- <img
                  [src]="detail.images[0].url"
                  alt="{{ detail.name }}"
                  width="250"
                  *ngIf="productDetails.type !== 'MEMBERSHIP'"
                
                /> -->
                      <button
                        class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                        (click)="payOrder(products_family_detail.id)"
                      >
                        Attiva ora
                      </button>

                      <p class="small text-center fw-normal">{{products_family_detail.name === "Mensile" ? "Pagamento mensile, disdici quando vuoi" : "Pagamento su base annuale"}}</p>
                      <!-- <button
                        class="do3-secondary-btn lightBlue mb-0 me-3 percorso-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenteredScrollable"
                        (click)="getProductDetails(detail.id)"
                      >
                        Visualizza Dettagli
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-template>
  </div>
</ng-container>

<!-- Modal Contenuto Percorso non utilizzato
<div
  class="modal fade"
  id="exampleModalCenteredScrollable"
  tabindex="-1"
  aria-labelledby="exampleModalCenteredScrollableTitle"
  aria-modal="true"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h1
          class="modal-title fs-5 text-uppercase"
          id="exampleModalCenteredScrollableTitle"
        >
          {{ additionalDetails?.title }}
        </h1>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="additionalDetails">
          <img
            *ngIf="
              additionalDetails.images && additionalDetails.images.length > 0
            "
            [src]="additionalDetails.images[0].url"
            alt="Cover Image"
            class="img-fluid mb-3 mx-auto d-block"
            width="300"
          />
          <div class="d-flex justify-content-between mb-1">
            <div class="tags">
              <span
                class="badge lightBlue text-uppercase"
                *ngFor="let tag of additionalDetails.tags"
                >{{ tag }}</span
              >
            </div>
            <div class="profile">
              <span class="badge borderColor-drfeel text-lowercase me-1">{{
                additionalDetails.gender
              }}</span>
              <span class="badge borderColor-drfeel">{{
                additionalDetails.ageRange
              }}</span>
            </div>
          </div>
          <p>{{ additionalDetails.description }}</p>
          <p></p>
          <div class="row g-2 mb-1">
            <div
              class="col-6"
              *ngFor="let feature of additionalDetails.features"
            >
              <div class="card text-center text-white bg-black h-100">
                <div class="card-body">
                  <div>
                    <img [src]="feature.icon.url" class="mx-auto d-block" />
                  </div>
                  <h5 class="card-title">{{ feature.title }}</h5>
                  <p class="card-text">{{ feature.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="specializations my-1"
            *ngIf="additionalDetails.physicianTypes"
          >
            <h2 class="m-0">Specialisti</h2>
            <span
              *ngFor="let physicianType of additionalDetails.physicianTypes"
              class="badge text-lowercase me-1"
              [ngClass]="{
                health: physicianType.tag === 'health',
                nutrition: physicianType.tag === 'nutrition',
                mind: physicianType.tag === 'mind',
                movement: physicianType.tag === 'movement'
              }"
            >
              <img [src]="physicianType.icon.url" class="img-fluid" />
              {{ physicianType.title }}
            </span>
          </div>
          <div class="includes my-1" *ngIf="additionalDetails.pathOffers">
            <h2 class="m-0">Cosa include</h2>
            <div class="card-container">
              <div
                *ngFor="let pathOffer of additionalDetails.pathOffers"
                class="card text-center text-white bg-black col-3 align"
              >
                <div class="card-body align-items-center">
                  <div>
                    <img [src]="pathOffer.icon.url" class="mx-auto d-block" />
                  </div>
                  <h5 class="card-title">{{ pathOffer.title }}</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="pathDetails my-1" *ngIf="additionalDetails.pathDetails">
            <h2 class="m-0">Dettagli del percorso</h2>
            <div class="timeline">
              <div class="row">
                <div class="col-md-12">
                  <div class="timeline-container">
                    <div
                      *ngFor="
                        let pathDetail of additionalDetails.pathDetails;
                        let i = index
                      "
                      class="timeline-item"
                    >
                      <div class="timeline-content">
                        <div
                          class="d-flex bd-highlight align-items-center justify-content-between"
                        >
                          <div class="timeline-number-container bd-highlight">
                            <div class="timeline-number">{{ i + 1 }}</div>
                          </div>
                          <h3 class="timeline-title m-0 bd-highlight">
                            {{ pathDetail.title }}
                          </h3>
                          <button
                            class="btn btn-link ms-auto bd-highlight"
                            type="button"
                            data-bs-toggle="collapse"
                            [attr.data-bs-target]="'#collapse' + i"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 -960 960 960"
                              width="24"
                            >
                              <path
                                d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"
                              />
                            </svg>
                          </button>
                        </div>
                        <div class="collapse" [attr.id]="'collapse' + i">
                          <div class="card card-body">
                            {{ pathDetail.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="!additionalDetails">
          <p>Caricamento...</p>
        </ng-container>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="do3-secondary-btn lightBlue w-25 text-uppercase"
          data-bs-dismiss="modal"
        >
          Chiudi
        </button>
      </div>
    </div>
  </div>
</div>
-->