<div class="container do3-gray-text">
  <div class="privacy-policy row">
    <div class="offset-lg-1 col-lg-10">

      <div class="text-card">
        <p>
          <span class="do3-fw900">Informativa sul trattamento dei dati personali</span>
          (Codice della privacy - d.lgs. 30.06.2003 n. 196, art. 13 - Regolamento UE 679/2016 sul trattamento dei dati
          personali, artt. 13 e 14)

        </p>
        <br>
        <p>
          <span class="do3-fw900">Gentile Utente,</span> <br>
          prima che Lei fornisca i dati personali che la riguardano, in armonia con quanto previsto dal Regolamento
          generale sulla protezione dei dati – Regolamento (UE) 2016/679 – e dal d.lgs. 196/03 (“Codice Privacy”), così
          come novellato dal d.lgs. 101/18, è necessario che prenda visione di una serie di informazioni che la Lab46
          S.r.l. (di seguito, anche il “Titolare del trattamento dati”) intende fornirle e che possono aiutarla a
          comprendere le motivazioni per le quali i Suoi dati personali verranno trattati, spiegando quali sono i Suoi
          diritti e come li si potrà concretamente esercitare. In questa pagina sono descritte le modalità di gestione
          della Piattaforma Dottorionline relativamente al trattamento dei dati personali degli utenti che decidono di
          utilizzarla. I dati fornitici saranno trattati nel rispetto della norma sopra richiamata, rispondendo agli
          obblighi di riservatezza e liceità a cui è ispirata la nostra società e per il tempo strettamente necessario
          per cui sono stati raccolti. Si rende noto che il presente documento è pubblicato all’indirizzo
          https://dottorionline.com, rappresenta la Privacy Policy di questo sito e può essere modificato in ragione di
          aggiornamenti.
        </p>
        <br>

        <p>
          <span class="do3-fw900">Origine dei dati personali</span> <br>
          Gli Utenti sono informati in merito alle finalità per le quali i loro dati personali vengono raccolti
          attraverso (I) le informazioni indicate nei vari moduli di raccolta dati online, (II) l’Informativa sui cookie
          e (III) la presente Informativa. Dati Personali raccolti presso l’Utente. Tutti i Dati Personali riguardanti
          gli Utenti sono raccolti da Dottorionline al momento della creazione dei loro Account Utente o tramite i
          moduli o altri documenti che essi compilano nell’ambito dell’utilizzo dei Servizi; o dal Professionista al
          momento della prenotazione di appuntamenti online e più in generale nel contesto dei Servizi offerti da
          Dottorionline. I Dati personali raccolti automaticamente da Dottorionline al momento in cui si utilizzano i
          Servizi. Quando gli Utenti utilizzano i Servizi, Dottorionline raccoglie automaticamente Dati Personali, in
          particolare attraverso l’uso di Cookie.
        </p>
        <br>

        <p>
          <span class="do3-fw900">Titolare del trattamento</span> <br>
          La società Lab46 S.r.l., con sede legale in Napoli, via Giovanni Porzio SN, Centro Direzionale, Isola F2, cap.
          80143 (di seguito la “Società”), informa di essere Titolare del trattamento, ai sensi degli artt. 4 e 28 del
          d.lgs. n. 196/2003 e degli artt. 4 e 24 del Regolamento UE, con riguardo al trattamento dei dati personali
          degli Utenti raccolti nell’ambito della creazione e gestione dell’Account Utente, della loro navigazione sul
          Sito e dell’utilizzo della Piattaforma. I Professionisti scelti dall’Utente saranno Titolari del trattamento
          dei Dati Personali e dati sensibili raccolti nell’ambito della prenotazione di appuntamenti online e più in
          generale nel contesto dei Servizi offerti da Dottorionline. In tal caso la società Lab46 S.r.l. interviene in
          qualità di Responsabile del trattamento. Che sia Titolare del trattamento o Responsabile del trattamento, la
          “Società” adotta misure appropriate per garantire la protezione e la riservatezza dei Dati personali che
          detiene o tratta in conformità con le disposizioni del GDPR e delle legislazioni nazionali. Per specifiche
          esigenze tecniche ed organizzative, la Società si avvale di soggetti terzi a cui affida parte del proprio
          processo. Tali soggetti possono assumere il ruolo di “Incaricati” o di “Responsabili” del trattamento dei dati
          personali della Società, oppure, operare in autonomia come distinti “Titolari” di successivi trattamenti
          aventi le medesime finalità perseguite dalla società. Per trattamento dei dati si intende qualsiasi operazione
          o insieme di operazioni, compiute con o senza l’ausilio di processi automatizzati e applicate ai dati
          personali o insieme di dati personali, anche se non registrati in una banca dati, come la raccolta,
          l’organizzazione, la strutturazione, la conservazione, l’elaborazione, la selezione, il blocco, l’adattamento
          o la modifica, l’estrazione, la consultazione, l’uso e la comunicazione mediante trasmissione o qualsiasi
          altra forma messa a disposizione, il raffronto, la limitazione, la cancellazione o la distruzione.
          <br> <br>
          Con riferimento a tali dati, La informiamo che:
        </p>
        <ul>
          <li>
            i dati vengono trattati in relazione alle esigenze contrattuali ed ai conseguenti adempimenti degli obblighi
            legali e contrattuali dalle stesse derivanti;
          </li>
          <li>
            i dati verranno trattati in forma scritta e/o su supporto magnetico, elettronico o telematico;
          </li>
          <li>
            il conferimento dei dati stessi è obbligatorio per tutto quanto è richiesto dagli obblighi legali e
            contrattuali e pertanto l’eventuale rifiuto a fornirli o al successivo trattamento potrà determinare
            l’impossibilità della Società a dar corso al rapporto contrattuale medesimo;
          </li>
          <li>
            il mancato conferimento, invece, di tutti i dati che non siano riconducibili ad obblighi legali o
            contrattuali verrà valutato di volta in volta dalla Società e determinerà le conseguenti decisioni
            rapportate all’importanza dei dati richiesti rispetto alla gestione del rapporto commerciale.
          </li>
        </ul>
        <br>
        <p>
          <span class="do3-fw900">2.Responsabile della Protezione dei Dati </span> <br>
          Il Responsabile della Protezione dei Dati è la persona a cui l’Utente interessato potrà rivolgersi per avere
          informazioni e segnalare eventuali problemi o disguidi inerenti i Suoi dati personali. Il Responsabile della
          nostra azienda è contattabile mediante l’invio di una lettera indirizzata a Responsabile Protezione Dati –
          Lab46 S.r.l. con sede legale in Napoli, via Giovanni Porzio SN, Centro Direzionale, Isola F2, cap. 80143 –
          ovvero tramite e-mail al seguente indirizzo di posta elettronica certificata lab46nasrl@legalmail.it.
        </p>
        <br>

        <p>
          <span class="do3-fw900">3.Tipologia dei dati trattati </span> <br>
          Durante l’utilizzo della piattaforma Dottorionline l’utente fornisce volontariamente i seguenti dati
          personali:
        </p>
        <ul>
          <li>
            Dati anagrafici;
          </li>
          <li>
            Dati di contatto (recapiti telefonici ed e-mail), per l’utilizzo al fine esclusivo di contattare il soggetto
            in caso di bisogno e per le finalità previste nella presente informativa;
          </li>
          <li>
            Codice fiscale;
          </li>
          <li>
            Indirizzo di domicilio;
          </li>
          <li>
            dati di navigazione, trattati al fine di garantire il corretto funzionamento del Sito o per ottenere
            informazioni sulle sue preferenze e abitudini di utilizzo del Sito. Tali dati potrebbero essere trattati
            anche attraverso cookies. A tal riguardo La invitiamo a leggere la specifica Cookie Policy presente sul
            Sito;
          </li>
          <li>
            categorie particolari di Dati Personali, così come definiti dall’art. 9 del GDPR, nella misura in cui siano
            forniti dall’Interessato per ricevere prestazioni e/o servizi dal Titolare o da terzi, per il tramite del
            Titolare.
          </li>
        </ul>
        <br>
        <p>
          <span class="do3-fw900">4. Dati personali dei minori</span> <br>
          La registrazione alla Piattaforma Dottori Online è consentita solo ai maggiorenni. I minori, quindi, possono
          accedere ai servizi solo attraverso la richiesta di chi esercita legittimamente la responsabilità
          genitoriale/la tutela. In ogni caso, il/i soggetto/i esercente/i la responsabilità genitoriale o la tutela su
          un minore hanno la facoltà di registrarsi e richiedere il supporto specialistico per il minore.
        </p>
        <br>
        <p>
          <span class="do3-fw900">5. Finalità del trattamento</span> <br>
          Tutti i dati personali e sensibili comunicati dal soggetto Interessato, sono trattati dal Titolare del
          trattamento per le seguenti finalità:
        </p>
        <ul>
          <li>
            consentire l’erogazione dei servizi offerti dal Titolare, per i quali si prevede che il trattamento possa
            essere effettuato senza raccogliere il consenso degli Interessati;
          </li>
          <li>
            creazione e gestione dell’Account Utente;
          </li>
          <li>
            per ottemperare ad eventuali e specifiche richieste dell’Utente;
          </li>
          <li>
            per permettere di prenotare un appuntamento con il Professionista scelto dall'Utente;
          </li>
          <li>
            per consentire di tenere aggiornato l'Utente sullo stato degli appuntamenti, in particolare: (i) al momento
            della prenotazione, tramite l'invio di un’e-mail e/o di un SMS di conferma dell'appuntamento; (ii)
            all'approssimarsi della data dell'appuntamento, tramite un’e-mail e/o un SMS di promemoria; (iii) in caso di
            cancellazione dell'appuntamento, tramite un’e-mail e/o un SMS di avvertimento; (iv) dopo l'appuntamento,
            tramite strumenti per valutare il grado di soddisfazione e per controllo qualità;
          </li>
          <li>
            per comunicare privatamente col Professionista scelto dall'Utente;
          </li>
          <li>
            per consentirle di pubblicare delle recensioni relative ai Professionisti;
          </li>
          <li>
            per fornire l’assistenza tecnica richiesta dall'Utente o prevista in Suo favore, nonché per l’espletamento
            delle attività strettamente connesse;
          </li>
          <li>
            per l’adempimento degli obblighi previsti da leggi, regolamenti, disposizioni emanate da autorità ed organi
            di vigilanza e controllo;
          </li>
          <li>
            fornitura agli Utenti d’informazioni relative alla gestione del loro Account Utente: informazioni e consigli
            su come utilizzare i Servizi (messaggio di benvenuto, nuove funzionalità, sviluppi, ecc.);
          </li>
          <li>
            utilizzo delle applicazioni e dei dispositivi (navigazione sul Sito e utilizzo della Piattaforma);
          </li>
          <li>
            per finalità di marketing diretto: con tale termine s’intende la volontà di svolgere nei Suoi confronti
            attività promozionali e/o di marketing. Rientrano in questa categoria tutte le attività compiute per
            promuovere prodotti, servizi, venduti e/o erogati dal Titolare del Trattamento o da parte di soggetti terzi
          </li>
          <li>
            per finalità di marketing indiretto: con tale termine s’intende la volontà di svolgere nei Suoi confronti
            attività promozionali e/o di marketing per conto di terzi. Rientrano in questa categoria tutte le attività
            compiute per promuovere prodotti, servizi, venduti e/o erogati da soggetti terzi con i quali il Titolare del
            Trattamento intrattiene rapporti giuridici senza che in questo caso vi sia comunicazione di dati.
          </li>
        </ul>
        <p>
          Per le finalità di marketing qualsiasi trattamento dei dati personali si basa su un consenso esplicito fornito
          all’atto della registrazione, ferma restando l’opportunità di annullare tali comunicazioni in qualsiasi
          momento contattando il DPO della società titolare all’email privacy@lab46.it. Con riguardo ai trattamenti
          svolti per finalità di "marketing diretto", il consenso dell’interessato acquisito ai sensi dell´art. 130,
          commi 1 e 2, del Codice, riguarda le modalità di comunicazione non solo automatizzate, ma anche tradizionali.
          Il diritto di opposizione dell’interessato al trattamento dei propri dati personali per le suddette finalità
          attraverso modalità automatizzate di contatto si estende all’invio di comunicazioni promozionali con modalità
          tradizionali, salvo, anche in tale ipotesi, il diritto per l'interessato di opporsi in parte al trattamento,
          così come previsto dall´art. 7, comma 4, del Codice. L’interessato, il quale non intenda prestare il consenso
          nei termini sopra indicati, ha la facoltà di manifestare l’eventuale volontà di ricevere comunicazioni per le
          suddette finalità esclusivamente attraverso modalità tradizionali di contatto, ove previste; per finalità di
          profilazione: con tale termine s’intende la volontà di profilarla ossia valutare i Suoi gusti, preferenze ed
          abitudini di consumo anche correlate ad indagini di mercato ed analisi di tipo statistico. Rientra in questa
          categoria qualsiasi forma di Trattamento automatizzato di Dati Personali per valutare determinati aspetti
          personali quali quelli riguardanti, a titolo esemplificativo e non esaustivo, il rendimento professionale, la
          situazione economica, le preferenze personali, gli interessi, l’affidabilità, il comportamento, l’ubicazione o
          gli spostamenti.
        </p>
        <br>
        <p>
          <span class="do3-fw900">Basi giuridiche che legittimano il trattamento</span> <br>
          Il trattamento dei dati raccolti per la corretta attuazione delle attività direttamente o indirettamente
          connesse ai servizi erogati in modalità online, è legittimato dagli artt. del GDPR, come esplicitato di
          seguito. <br>
          Il titolare tratta i dati personali relativi all’Utente in caso sussista una delle seguenti condizioni:
        </p>
        <ul>
          <li>
            il trattamento è necessario per l’esecuzione del rapporto precontrattuale e/o contrattuale con l’Utente,
            nonché per finalità strettamente connesse e strumentali alla gestione dei rapporti con la clientela, come
            previsto dall'art. 6.1(b) del GDPR ;
          </li>
          <li>
            il trattamento è necessario per adempiere ad obblighi normativi e regolamentari al quale è soggetto il
            Titolare, come previsto dall'art. 6.1(c) del GDPR;
          </li>
          <li>
            il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o per l’esercizio di
            pubblici poteri di cui è investito il Titolare, come previsto dall'art. 6.1(e) del GDPR;
          </li>
          <li>
            il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi di
            rispettare le obbligazioni contrattuali sottoscritte tra le parti, come previsto dall'art. 6.1(f) del GDPR.
          </li>
        </ul>
        <p>
          Per le finalità di marketing diretto e indiretto e le finalità di profilazione qualsiasi trattamento dei dati
          personali si basa su un consenso esplicito fornito dall'utente. Si applica, altresì, quanto previsto
          all’articolo 9, paragrafo 2, lettere a), f), g), h) e paragrafo 3 del Regolamento UE 2016/679. 
        </p>
        <br>
        <p>
          <span class="do3-fw900">Modalità del trattamento</span> <br>
          Il trattamento in oggetto è svolto secondo le modalità previste dal Regolamento UE 2016/679, anche a mezzo di
          strumenti informatici e automatizzati, in via non esaustiva attraverso operazioni di raccolta, registrazione,
          organizzazione, conservazione, elaborazione, selezione, raffronto, utilizzo, interconnessione, consultazione,
          comunicazione, cancellazione, distruzione, blocco dei dati, secondo principi di tutela della
          sicurezza/protezione, accessibilità, confidenzialità, integrità. Gli stessi dati sono trattati e detenuti nei
          termini di quanto obbligatoriamente previsto dalla legge, nei limiti e per le modalità dalla stessa
          specificate. Il trattamento dei suoi dati personali avviene presso gli uffici del Titolare del trattamento, o
          qualora fosse necessario, presso i soggetti indicati al paragrafo 9, con l’osservanza di ogni misura
          cautelativa, che ne garantisca la sicurezza e la riservatezza. Il trattamento si svilupperà in modo da ridurre
          al minimo il rischio di distruzione o perdita, di accesso non autorizzato, di trattamento non conforme alle
          finalità della raccolta dei dati stessi. Data la natura del servizio erogato, i dati personali sono trattati
          con strumenti informatici nel rispetto:
        </p>
        <ul>
          <li>
            nel rispetto del principio di minimizzazione, ai sensi degli articoli 5.1.c e 25.2 del Regolamento
            679/2016/UE;
          </li>
          <li>
            in modo lecito e secondo correttezza. I suoi dati sono raccolti:
          </li>
          <li>
            per scopi determinati espliciti e legittimi;
          </li>
          <li>
            esatti e se necessario aggiornati;
          </li>
          <li>
            pertinenti, completi e non eccedenti rispetto alle finalità del trattamento.
          </li>
        </ul>
        <p>
          Si rende noto che la disponibilità delle informazioni, dati, eventuali immagini rilevate durate l’erogazione
          del servizio sarà garantita solo ai soggetti autorizzati al trattamento. Tutte le operazioni in materia,
          effettuate solo da personale debitamente istruito ed autorizzato dal Titolare o suo delegato, avverranno nel
          rispetto del segreto professionale, del segreto d'ufficio e dei principi di correttezza, liceità e
          trasparenza, secondo quanto disposto dalle normative vigenti.
        </p>
        <br>

        <p>
          <span class="do3-fw900">Soggetti o categorie di soggetti cui i dati possono essere comunicati </span> <br>
          I dati personali dell’Interessato, nei casi in cui risultasse necessario, potranno essere comunicati (con tale
          termine intendendosi il darne conoscenza ad uno o più soggetti determinati):
        </p>
        <ul>
          <li>
            ai soggetti la cui facoltà di accesso ai dati è riconosciuta da disposizioni di legge, normativa secondaria,
            comunitaria, nonché di contrattazione collettiva;
          </li>
          <li>
            ai soggetti ai quali la comunicazione dei dati personali risulti necessaria o comunque funzionale alla
            gestione della prestazione richiesta nei modi e per le finalità sopra illustrate. Si rende edotto
            l’Interessato che il conferimento dei dati personali oggetto della presente informativa risulta essere
            necessario al fine di poter erogare i servizi presenti sulla piattaforma Dottorionline. Nell'eventualità in
            cui tali dati non venissero correttamente forniti non sarà possibile soddisfare le richieste
            dell’Interessato. Si comunica che verrà richiesto specifico ed espresso consenso nell'eventualità in cui si
            verificasse la necessità di una comunicazione di dati a soggetti terzi non espressamente indicati.
          </li>
        </ul>
        <br>
        <p>
          <span class="do3-fw900">Diritti dell’interessato in relazione al trattamento dei dati personali </span> <br>
          Ai sensi degli artt. 15, 16, 17, 18, 19, 20,21 e 22 del Regolamento, La informiamo che ha diritto:
        </p>
        <ul style="list-style-type: none;">
          <li>
            a) Diritto di chiedere al Titolare del trattamento, ex Art. 15 Reg. 679/2016, di poter accedere ai propri
            dati personali;
          </li>
          <li>
            b) Diritto di chiedere al Titolare del trattamento, ex Art. 16 Reg. 679/2016, di poter rettificare i propri
            dati personali, ove quest’ultimo non contrasti con la normativa vigente sulla conservazione dei dati stessi
            ;
          </li>
          <li>
            c) Diritto di chiedere al Titolare del trattamento, ex Art. 17 Reg. 679/2016, di poter cancellare i propri
            dati personali, ove quest’ultimo non contrasti con la normativa vigente sulla conservazione dei dati stessi;
          </li>
          <li>
            d) Diritto di chiedere al Titolare del trattamento, ex Art. 18 Reg. 679/2016, di poter limitare il
            trattamento dei propri dati personali;
          </li>
          <li>
            e) Diritto di opporsi al trattamento, ex Art. 21 Reg. 679/2016;
          </li>
          <li>
            f) Diritto di chiedere al Titolare del trattamento, solamente nei casi previsti all’art. 20 del reg.
            679/2016, che venga compiuta la trasmissione dei propri dati personali ad altro operatore sanitario in
            formato leggibile.
          </li>
          <li>
            g) Diritto di opposizione: l’interessato ha il diritto di opporsi in qualunque momento , per motivi connessi
            alla sua situazione particolare, al trattamento dei dati personali che lo riguardano ai sensi dell'articolo
            6, paragrafo 1, lettere e) o f), compresa la profilazione sulla base di tali disposizioni, fatta salva
            l’esistenza di motivi legittimi che prevalgono sugli interessi, sui diritti e sulle libertà fondamentali
            dell’interessato oppure per l’accertamento, l’esercizio o la difesa di un diritto in sede giudiziaria.
            Qualora i dati personali siano trattati per finalità di marketing diretto, l'interessato ha il diritto di
            opporsi in qualsiasi momento al trattamento dei dati personali che lo riguardano effettuato per tali
            finalità, compresa la profilazione nella misura in cui sia connessa a tale marketing diretto.
          </li>
          <li>
            h) Diritto di revoca del consenso: applicabile esclusivamente ai trattamenti effettuati sulla base del
            rilascio del consenso rimanendo tuttavia valido per i trattamenti effettuati precedentemente alla revoca.
          </li>
          <li>
            i) Diritto di proporre un reclamo all’Autorità Garante per la protezione dei dati personali, per l’esercizio
            dei suoi diritti o per qualsiasi altra questione relativa al trattamento dei suoi dati personali seguendo le
            procedure e le indicazioni pubblicate sul sito ufficiali dell’Autorità su www.garanteprivacy.it. L’esercizio
            dei diritti non è soggetto ad alcun vincolo di forma ed è gratuito. Per l'esercizio di tali diritti potrà
            rivolgersi direttamente a Lab46 S.r.l. con sede legale in via Giovanni Porzio SN, Centro Direzionale, Isola
            F2, Napoli, cap. 80143, o a mezzo pec, inviata all’indirizzolab46nasrl@legalmail.it.
          </li>
        </ul>
        <br>
        <p>
          <span class="do3-fw900">Natura obbligatoria o necessaria del conferimento dei dati e conseguenze di un
            eventuale rifiuto</span> <br>
          A seconda delle finalità, il conferimento dei dati personali costituisce un obbligo legale, contrattuale o un
          requisito necessario per l’erogazione dei servizi presenti sulla piattaforma Dottorionline. In altre parole, i
          dati richiesti sono necessari per consentire il funzionamento della piattaforma e in loro assenza potrebbe non
          essere possibile utilizzarla ed usufruire dei servizi. Una volta che l’utente abbia deciso di utilizzare i
          servizi offerti dalla Piattaforma, il conferimento dei dati è necessario per la corretta erogazione dei
          servizi stessi e la mancata trasmissione degli stessi o una loro parziale o inesatta trasmissione comporterà
          l’impossibilità per l’utente di fruire tali servizi.

        </p>
        <br>
        <p>
          <span class="do3-fw900">Tempi di conservazione dei dati e altre informazioni </span> <br>
          I Suoi dati verranno trattati e conservati per il tempo richiesto dalle finalità per le quali sono stati
          raccolti. Pertanto, i dati personali raccolti per scopi collegati all’esecuzione di un contratto tra il
          Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto. Quando il
          trattamento è basato sul consenso dell’Utente, il Titolare può conservare i dati personali più a lungo sino a
          quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i dati
          personali per un periodo più lungo in ottemperanza di un obbligo di legge o per ordine di un’autorità. Al
          termine del periodo di conservazione i dati personali saranno cancellati. Pertanto, allo spirare di tale
          termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei dati non
          potranno più essere esercitati.

        </p>
        <br>
        <p>
          <span class="do3-fw900">Unione Europea</span> <br>
          I dati personali dell'Utente interessato saranno trattati dal Titolare del Trattamento all’interno del
          territorio dell’Unione Europea. Qualora per questioni di natura tecnica e/o operativa si renda necessario
          avvalersi di soggetti ubicati al di fuori dell’Unione Europea, La informiamo sin d’ora che tali soggetti
          saranno nominati Responsabili del Trattamento ai sensi e per gli effetti di cui all’articolo 28 del
          Regolamento ed il trasferimento dei Suoi dati personali a tali soggetti, limitatamente allo svolgimento di
          specifiche attività di Trattamento, sarà regolato in conformità a quanto previsto dal capo V del Regolamento.
          Saranno quindi adottate tutte le cautele necessarie al fine di garantire la più totale protezione dei Suoi
          dati personali basando tale trasferimento: (a) su decisioni di adeguatezza dei paesi terzi destinatari
          espresse dalla Commissione Europea; (b) su garanzie adeguate espresse dal soggetto terzo destinatario ai sensi
          dell’articolo 46 del Regolamento; (c) sull’adozione di norme vincolanti d’impresa, c.d. corporate binding
          rules. In ogni caso potrà richiedere maggiori dettagli al Titolare del Trattamento qualora i Suoi dati
          personali siano stati trattati al di fuori dell’Unione Europea richiedendo evidenza delle specifiche garanzie
          adottate.
        </p>
        <br>
        <p>
          <span class="do3-fw900">Modifiche a questa Informativa sul trattamento dei dati personali </span> <br>
          La presente informativa è stata aggiornata a marzo 2022. In caso si rendessero necessari aggiornamenti, sarà
          cura della Società informare l’Utente, attraverso modalità ritenute più opportune (es. e-mail, sms, notifiche
          push etc.) delle modifiche occorse.
        </p>
      </div>
    </div>
  </div>
</div>
